// import React, { useState } from 'react';
// import useAuth from '../../hooks/useAuth';
// import { useNavigate } from 'react-router-dom';

// const Login = () => {
//     const { login, loading } = useAuth();
//     const [username, setUsername] = useState('');
//     const [password, setPassword] = useState('');
//     const navigate = useNavigate();

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         try {
//             await login(username, password);
//             navigate('/dashboard'); // Redirect to a protected route on successful login
//         } catch (error) {
//             console.error('Login failed:', error);
//             // Handle login error (e.g., show an error message)
//         }
//     };

//     return (
//         <div className="login-container">
//             <form onSubmit={handleSubmit}>
//                 <label>
//                     Username:
//                     <input
//                         type="text"
//                         value={username}
//                         onChange={(e) => setUsername(e.target.value)}
//                     />
//                 </label>
//                 <label>
//                     Password:
//                     <input
//                         type="password"
//                         value={password}
//                         onChange={(e) => setPassword(e.target.value)}
//                     />
//                 </label>
//                 <button type="submit" disabled={loading}>
//                     {loading ? 'Logging in...' : 'Login'}
//                 </button>
//             </form>
//             {loading && (
//                 <div>loading...</div>
//             )}
//         </div>
//     );
// };

// export default Login;










import React, { useState } from 'react';
import useAuth from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';

const Login = () => {
    const { login, loading } = useAuth();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await login(username, password);
            navigate('/dashboard');
        } catch (error) {
            if (error.response && error.response.data) {
                const errorMessage = error.response.data.detail || 'Login failed';
                const errors = error.response.data.errors || {};
                setError(errorMessage);
            } else {
                setError('An unexpected error occurred. Please try again later.');
            }
        }
    };

    return (
        <div className="flex justify-center items-center min-h-screen bg-primary-50">
            <div className="w-full max-w-md p-8 space-y-6 bg-white shadow-md rounded-lg">
                <h3 className="text-2xl font-bold text-center">Login</h3>
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                        <label htmlFor="username" className="block text-sm font-medium text-gray-700">Username</label>
                        <input
                            type="text"
                            id="username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            className="input-style"
                        />
                    </div>
                    <div className="relative">
                        <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password</label>
                        <input
                            type={showPassword ? 'text' : 'password'}
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="input-style"
                        />
                        <div
                            onClick={() => setShowPassword(!showPassword)}
                            className="password-toggle"
                        >
                            {showPassword ? (
                                <i className="fas fa-eye-slash"></i>
                            ) : (
                                <i className="fas fa-eye"></i>
                            )}
                        </div>
                    </div>
                    <button
                        type="submit"
                        disabled={loading}
                        className="button-style"
                    >
                        {loading ? 'Logging in...' : 'Login'}
                    </button>
                </form>
                {loading && (
                    <div className="text-center text-sm text-gray-500">Loading...</div>
                )}
                {error && (
                    <div className="error-message text-center">{error}</div>
                )}
            </div>
        </div>
    );
};

export default Login;











// import React, { useState } from 'react';
// import useAuth from '../../hooks/useAuth';
// import { useNavigate } from 'react-router-dom';

// const Login = () => {
//     const { login, loading } = useAuth();
//     const [username, setUsername] = useState('');
//     const [password, setPassword] = useState('');
//     const [showPassword, setShowPassword] = useState(false);
//     const [error, setError] = useState(null);
//     const navigate = useNavigate();

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         try {
//             await login(username, password);
//             navigate('/dashboard'); // Redirect to a protected route on successful login
//         } catch (error) {
//             if (error.response && error.response.data) {
//                 const errorMessage = error.response.data.detail || 'Login failed';
//                 const errors = error.response.data.errors || {};
//                 setError(errorMessage);
//                 console.error('Login failed:', errorMessage, errors);
//             } else {
//                 setError('An unexpected error occurred. Please try again later.');
//             }
//         }
//     };
    
//     // const handleSubmit = async (e) => {
//     //     e.preventDefault();
//     //     try {
//     //         await login(username, password);
//     //         navigate('/dashboard'); // Redirect to a protected route on successful login
//     //     } catch (error) {
//     //         setError(error.message);
//     //         console.error('Login failed:', error);
//     //         // Handle login error (e.g., show an error message)
//     //     }
//     // };





//     return (
//         <div className="flex justify-center items-center min-h-screen bg-primary-50">
//             <div className="w-full max-w-md p-8 space-y-6 bg-white shadow-md rounded-lg">
//                 {/* <h3 className="text-2xl font-bold text-center">تسجيل الدخول</h3> */}
//                 <form onSubmit={handleSubmit} className="space-y-4">
//                     <div>
//                         <label className="block text-sm font-medium text-gray-700">اسم المستخدم</label>
//                         <input
//                             type="text"
//                             value={username}
//                             onChange={(e) => setUsername(e.target.value)}
//                             className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-green-100 focus:border-blue-100"
//                         />
//                     </div>
//                     <div className="relative">
//                         <label className="block text-sm font-medium text-gray-700">كلمة المرور</label>
//                         <input
//                             type={showPassword ? 'text' : 'password'} // Toggle input type
//                             value={password}
//                             onChange={(e) => setPassword(e.target.value)}
//                             className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-green-100 focus:border-blue-100"
//                         />
//                         {/* Eye Icon */}
//                         <div
//                             onClick={() => setShowPassword(!showPassword)} // Toggle password visibility
//                             className="absolute left-3 bottom-3 cursor-pointer"
//                         >
//                             {showPassword ? (
//                                 <svg
//                                     xmlns="http://www.w3.org/2000/svg"
//                                     className="h-5 w-5 text-gray-500"
//                                     viewBox="0 0 24 24"
//                                     fill="none"
//                                     stroke="currentColor"
//                                     strokeWidth="2"
//                                     strokeLinecap="round"
//                                     strokeLinejoin="round"
//                                 >
//                                     <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" />
//                                     <circle cx="12" cy="12" r="3" />
//                                 </svg>
//                             ) : (
//                                 <svg
//                                     xmlns="http://www.w3.org/2000/svg"
//                                     className="h-5 w-5 text-gray-500"
//                                     viewBox="0 0 24 24"
//                                     fill="none"
//                                     stroke="currentColor"
//                                     strokeWidth="2"
//                                     strokeLinecap="round"
//                                     strokeLinejoin="round"
//                                 >
//                                     <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" />
//                                     <path d="M4.93 4.93l14.14 14.14" />
//                                 </svg>
//                             )}
//                         </div>
//                     </div>
//                     <button
//                         type="submit"
//                         disabled={loading}
//                         className="w-full py-2 px-4 btn-green"
//                     >
//                         {loading ? 'Logging in...' : 'دخول'}
//                     </button>
//                 </form>
//                 {loading && (
//                     <div className="text-center text-sm text-gray-500">Loading...</div>
//                 )}
//                 {error && (
//                     <div className='error-message text-center'>{error}</div>
//                 )}
//             </div>
//         </div>
//     );
// };

// export default Login;

