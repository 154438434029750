import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css'
import { setAuthToken } from './services/api';
// Fetch the token from local storage
const token = localStorage.getItem('token');

// Set the token for all Axios requests
setAuthToken(token);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);



// i removed strict mode for now
// <React.StrictMode>
//   <App />
// </React.StrictMode>