import React from "react";

const Card = ({ children }) => {
  return (
    // <div className="bg-white shadow-md rounded-lg p-6 max-w-fit mx-auto mb-5 mt-5">
    <div
      className="bg-white shadow-md p-6"
      // style={{
      //   maxWidth: "95%",
      //   margin: "30px auto",
      // }}
    >
      {children}
    </div>
  );
};

export default Card;
