import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { LogOut, Menu as MenuIcon } from "lucide-react";
import useAuth from "../../hooks/useAuth";

const Navbar = () => {
  const { logout, user, loading } = useAuth();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/login");
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  if (loading) {
    return null; // Or a loading spinner component if available
  }

  const navItems = [
    { to: "/home", label: "الصفحة الرئيسية" },
    { to: "/dashboard", label: "البطاقة المالية" },
    { to: "/calculation", label: "طلب تثبيت مزاولات" },
    { to: "/documents", label: "الوثائق المطلوبة" },
  ];

  return (
    <nav className="bg-gray-100 shadow-md">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex">
            <div className="flex-shrink-0 flex items-center">
              <div className="bg-emerald-200 w-10 h-10 rounded-full flex items-center justify-center text-emerald-800 font-bold mr-2">
                RYs
              </div>
              <span className="text-xl font-bold text-gray-800">خزانة التقاعد</span>
            </div>
          </div>
          
          {/* Desktop menu */}
          <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
            {user && navItems.map((item) => (
              <NavLink
                key={item.to}
                to={item.to}
                className={({ isActive }) =>
                  `${
                    isActive
                      ? "border-indigo-500 text-gray-900"
                      : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                  } inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium`
                }
              >
                {item.label}
              </NavLink>
            ))}
          </div>

          <div className="hidden sm:ml-6 sm:flex sm:items-center">
            {user ? (
              <div className="flex items-center">
                <span className="text-gray-700 mr-4">{user.username}</span>
                <button
                  onClick={handleLogout}
                  className="bg-indigo-600 p-1 rounded-full text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <LogOut className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            ) : (
              <div className="flex space-x-4">
                <NavLink
                  to="/login"
                  className="text-gray-700 hover:bg-gray-200 px-3 py-2 rounded-md text-sm font-medium"
                >
                  تسجيل دخول
                </NavLink>
                <NavLink
                  to="/signup"
                  className="bg-indigo-600 text-white hover:bg-indigo-700 px-3 py-2 rounded-md text-sm font-medium"
                >
                  إنشاء حساب
                </NavLink>
              </div>
            )}
          </div>

          {/* Mobile menu button */}
          <div className="flex items-center sm:hidden">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
            >
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      <div className={`${isMenuOpen ? "block" : "hidden"} sm:hidden`}>
        <div className="pt-2 pb-3 space-y-1">
          {user && navItems.map((item) => (
            <NavLink
              key={item.to}
              to={item.to}
              className={({ isActive }) =>
                `${
                  isActive
                    ? "bg-indigo-50 border-indigo-500 text-indigo-700"
                    : "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700"
                } block pl-3 pr-4 py-2 border-l-4 text-base font-medium`
              }
            >
              {item.label}
            </NavLink>
          ))}
        </div>
        <div className="pt-4 pb-3 border-t border-gray-200">
          {user ? (
            <div className="flex items-center px-4">
              <div className="flex-shrink-0">
                <div className="h-10 w-10 rounded-full bg-indigo-600 flex items-center justify-center">
                  <span className="text-white font-medium">{user.username.charAt(0).toUpperCase()}</span>
                </div>
              </div>
              <div className="ml-3">
                <div className="text-base font-medium text-gray-800">{user.username}</div>
              </div>
              <button
                onClick={handleLogout}
                className="ml-auto bg-indigo-600 flex-shrink-0 p-1 rounded-full text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <LogOut className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
          ) : (
            <div className="mt-3 space-y-1">
              <NavLink
                to="/login"
                className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
              >
                تسجيل دخول
              </NavLink>
              <NavLink
                to="/signup"
                className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
              >
                إنشاء حساب
              </NavLink>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;












// import React, { useState } from "react";
// import { NavLink, useNavigate } from "react-router-dom";
// import { LogOut, Menu, X } from "lucide-react";
// import useAuth from "../../hooks/useAuth";

// const Navbar = () => {
//   const { logout, user, loading } = useAuth();
//   const navigate = useNavigate();
//   const [isMenuOpen, setIsMenuOpen] = useState(false);

//   const handleLogout = async () => {
//     try {
//       await logout();
//       navigate("/login");
//     } catch (error) {
//       console.error("Logout failed:", error);
//     }
//   };

//   if (loading) {
//     return null;
//   }

//   const navLinks = [
//     { to: "/home", text: "الصفحة الرئيسية" },
//     { to: "/dashboard", text: "البطاقة المالية" },
//     { to: "/calculation", text: "طلب تثبيت مزاولات" },
//     { to: "/documents", text: "الوثائق المطلوبة" },
//   ];

//   return (
//     <header className="bg-white shadow-lg">
//       <nav className="container mx-auto px-4 py-3">
//         <div className="flex justify-between items-center">
//           {/* Logo Section */}
//           <div className="flex items-center space-x-2">
//             <div className="w-10 h-10 bg-gray-100 rounded-full flex items-center justify-center text-blue-500 font-bold text-xl shadow-md">
//               RYs
//             </div>
//             <span className="text-lg font-semibold text-gray-800">خزانة التقاعد</span>
//           </div>

//           {/* Mobile Menu Button */}
//           <button
//             onClick={() => setIsMenuOpen(!isMenuOpen)}
//             className="md:hidden text-gray-600 focus:outline-none"
//           >
//             {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
//           </button>

//           {/* Desktop Navigation */}
//           <div className="hidden md:flex items-center space-x-6">
//             {user && (
//               <ul className="flex space-x-6">
//                 {navLinks.map((link) => (
//                   <li key={link.to}>
//                     <NavLink
//                       to={link.to}
//                       className={({ isActive }) =>
//                         `text-gray-700 hover:text-blue-600 font-medium transition-colors ${
//                           isActive ? "border-b-2 border-blue-600 pb-1" : ""
//                         }`
//                       }
//                     >
//                       {link.text}
//                     </NavLink>
//                   </li>
//                 ))}
//               </ul>
//             )}

//             {/* User Actions */}
//             <div className="flex items-center space-x-4">
//               {user ? (
//                 <>
//                   <span className="text-gray-800 font-medium">{user.username}</span>
//                   <button
//                     onClick={handleLogout}
//                     className="bg-gray-100 text-blue-600 p-2 rounded-full hover:bg-blue-50 transition-colors shadow-sm"
//                   >
//                     <LogOut size={20} />
//                   </button>
//                 </>
//               ) : (
//                 <div className="space-x-4">
//                   <NavLink
//                     to="/login"
//                     className="text-gray-700 hover:text-blue-600 font-medium transition-colors"
//                   >
//                     تسجيل دخول
//                   </NavLink>
//                   <NavLink
//                     to="/signup"
//                     className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition-colors shadow-md"
//                   >
//                     إنشاء حساب
//                   </NavLink>
//                 </div>
//               )}
//             </div>
//           </div>
//         </div>

//         {/* Mobile Navigation */}
//         {isMenuOpen && (
//           <div className="md:hidden mt-4">
//             {user && (
//               <ul className="flex flex-col space-y-2">
//                 {navLinks.map((link) => (
//                   <li key={link.to}>
//                     <NavLink
//                       to={link.to}
//                       className={({ isActive }) =>
//                         `block text-gray-700 hover:bg-blue-100 px-3 py-2 rounded-md transition-colors ${
//                           isActive ? "bg-blue-100" : ""
//                         }`
//                       }
//                       onClick={() => setIsMenuOpen(false)}
//                     >
//                       {link.text}
//                     </NavLink>
//                   </li>
//                 ))}
//               </ul>
//             )}
//             <div className="mt-4 space-y-2">
//               {user ? (
//                 <>
//                   <span className="block text-gray-800 font-medium">{user.username}</span>
//                   <button
//                     onClick={handleLogout}
//                     className="w-full text-left bg-gray-100 text-blue-600 px-3 py-2 rounded-md hover:bg-blue-50 transition-colors shadow-sm"
//                   >
//                     تسجيل خروج
//                   </button>
//                 </>
//               ) : (
//                 <>
//                   <NavLink
//                     to="/login"
//                     className="block text-gray-700 hover:bg-blue-100 px-3 py-2 rounded-md transition-colors"
//                     onClick={() => setIsMenuOpen(false)}
//                   >
//                     تسجيل دخول
//                   </NavLink>
//                   <NavLink
//                     to="/signup"
//                     className="block bg-blue-500 text-white px-3 py-2 rounded-md hover:bg-blue-600 transition-colors shadow-md"
//                     onClick={() => setIsMenuOpen(false)}
//                   >
//                     إنشاء حساب
//                   </NavLink>
//                 </>
//               )}
//             </div>
//           </div>
//         )}
//       </nav>
//     </header>
//   );
// };

// export default Navbar;













// import React, { useState } from "react";
// import { NavLink, useNavigate } from "react-router-dom";
// import { LogOut , Menu, X } from "lucide-react";
// import useAuth from "../../hooks/useAuth";

// const Navbar = () => {
//   const { logout, user, loading } = useAuth();
//   const navigate = useNavigate();
//   const [isMenuOpen, setIsMenuOpen] = useState(false);

//   const handleLogout = async () => {
//     try {
//       await logout();
//       navigate("/login");
//     } catch (error) {
//       console.error("Logout failed:", error);
//     }
//   };

//   if (loading) {
//     return null; // Or a loading spinner component if available
//   }

//   const navLinks = [
//     { to: "/home", text: "الصفحة الرئيسية" },
//     { to: "/dashboard", text: "البطاقة المالية" },
//     { to: "/calculation", text: "طلب تثبيت مزاولات" },
//     { to: "/documents", text: "الوثائق المطلوبة" },
//   ];

//   return (
//     <header className="bg-gradient-to-r from-indigo-600 to-purple-600 shadow-lg">
//       <nav className="container mx-auto px-4 py-3">
//         <div className="flex justify-between items-center">
//           {/* Logo Section */}
//           <div className="flex items-center space-x-2">
//             <div className="w-10 h-10 bg-white rounded-full flex items-center justify-center text-indigo-600 font-bold text-xl shadow-md">
//               RYs
//             </div>
//             <span className="text-xl font-bold text-white">خزانة التقاعد</span>
//           </div>

//           {/* Mobile Menu Button */}
//           <button
//             onClick={() => setIsMenuOpen(!isMenuOpen)}
//             className="md:hidden text-white focus:outline-none"
//           >
//             {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
//           </button>

//           {/* Desktop Navigation */}
//           <div className="hidden md:flex items-center space-x-6">
//             {user && (
//               <ul className="flex space-x-6">
//                 {navLinks.map((link) => (
//                   <li key={link.to}>
//                     <NavLink
//                       to={link.to}
//                       className={({ isActive }) =>
//                         `text-white hover:text-indigo-200 font-semibold transition-colors ${
//                           isActive ? "border-b-2 border-white pb-1" : ""
//                         }`
//                       }
//                     >
//                       {link.text}
//                     </NavLink>
//                   </li>
//                 ))}
//               </ul>
//             )}

//             {/* User Actions */}
//             <div className="flex items-center space-x-4">
//               {user ? (
//                 <>
//                   <span className="text-white font-medium">{user.username}</span>
//                   <button
//                     onClick={handleLogout}
//                     className="bg-white text-indigo-600 p-2 rounded-full hover:bg-indigo-100 transition-colors"
//                   >
//                     <LogOut size={20} />
//                   </button>
//                 </>
//               ) : (
//                 <div className="space-x-4">
//                   <NavLink
//                     to="/login"
//                     className="text-white hover:text-indigo-200 font-semibold transition-colors"
//                   >
//                     تسجيل دخول
//                   </NavLink>
//                   <NavLink
//                     to="/signup"
//                     className="bg-white text-indigo-600 px-4 py-2 rounded-md hover:bg-indigo-100 transition-colors"
//                   >
//                     إنشاء حساب
//                   </NavLink>
//                 </div>
//               )}
//             </div>
//           </div>
//         </div>

//         {/* Mobile Navigation */}
//         {isMenuOpen && (
//           <div className="md:hidden mt-4">
//             {user && (
//               <ul className="flex flex-col space-y-2">
//                 {navLinks.map((link) => (
//                   <li key={link.to}>
//                     <NavLink
//                       to={link.to}
//                       className={({ isActive }) =>
//                         `block text-white hover:bg-indigo-500 px-3 py-2 rounded-md transition-colors ${
//                           isActive ? "bg-indigo-500" : ""
//                         }`
//                       }
//                       onClick={() => setIsMenuOpen(false)}
//                     >
//                       {link.text}
//                     </NavLink>
//                   </li>
//                 ))}
//               </ul>
//             )}
//             <div className="mt-4 space-y-2">
//               {user ? (
//                 <>
//                   <span className="block text-white font-medium">{user.username}</span>
//                   <button
//                     onClick={handleLogout}
//                     className="w-full text-left bg-white text-indigo-600 px-3 py-2 rounded-md hover:bg-indigo-100 transition-colors"
//                   >
//                     تسجيل خروج
//                   </button>
//                 </>
//               ) : (
//                 <>
//                   <NavLink
//                     to="/login"
//                     className="block text-white hover:bg-indigo-500 px-3 py-2 rounded-md transition-colors"
//                     onClick={() => setIsMenuOpen(false)}
//                   >
//                     تسجيل دخول
//                   </NavLink>
//                   <NavLink
//                     to="/signup"
//                     className="block bg-white text-indigo-600 px-3 py-2 rounded-md hover:bg-indigo-100 transition-colors"
//                     onClick={() => setIsMenuOpen(false)}
//                   >
//                     إنشاء حساب
//                   </NavLink>
//                 </>
//               )}
//             </div>
//           </div>
//         )}
//       </nav>
//     </header>
//   );
// };

// export default Navbar;




















// import React from "react";
// import { NavLink, useNavigate } from "react-router-dom";
// import { AlarmClockIcon } from "lucide-react";
// import useAuth from "../../hooks/useAuth";

// const Navbar = () => {
//   const { logout, user, loading } = useAuth();
//   const navigate = useNavigate();

//   const handleLogout = async () => {
//     try {
//       await logout();
//       navigate("/login");
//     } catch (error) {
//       console.error("Logout failed:", error);
//     }
//   };

//   if (loading) {
//     return null; // Or a loading spinner component if available
//   }

//   return (
//     <header className="bg-gray-100 shadow-md">
//       <nav className="container mx-auto px-4 py-3 flex justify-between items-center">
//         {/* Logo Section */}
//         <div className="flex items-center space-x-2">
//           <div className="w-10 h-10 bg-emerald-200 rounded-full flex items-center justify-center text-emerald-700 font-bold">
//             RYs
//           </div>
//           <span className="text-xl font-bold text-gray-800">خزانة التقاعد</span>
//         </div>

//         {/* Navigation Links */}
//         {user && (
//           <ul className="hidden md:flex space-x-6">
//             {[
//               { to: "/home", text: "الصفحة الرئيسية" },
//               { to: "/dashboard", text: "البطاقة المالية" },
//               { to: "/calculation", text: "طلب تثبيت مزاولات" },
//               { to: "/documents", text: "الوثائق المطلوبة" },
//             ].map((link) => (
//               <li key={link.to}>
//                 <NavLink
//                   to={link.to}
//                   className={({ isActive }) =>
//                     `text-gray-600 hover:text-blue-600 font-semibold transition-colors ${
//                       isActive ? "text-blue-600" : ""
//                     }`
//                   }
//                 >
//                   {link.text}
//                 </NavLink>
//               </li>
//             ))}
//           </ul>
//         )}

//         {/* User Actions */}
//         <div className="flex items-center space-x-4">
//           {user ? (
//             <>
//               <span className="text-gray-600 font-medium">{user.username}</span>
//               <button
//                 onClick={handleLogout}
//                 className="bg-blue-500 hover:bg-blue-600 text-white p-2 rounded-full transition-colors"
//               >
//                 <AlarmClockIcon size={20} />
//               </button>
//             </>
//           ) : (
//             <div className="space-x-4">
//               <NavLink
//                 to="/login"
//                 className="text-blue-600 hover:text-blue-700 font-semibold transition-colors"
//               >
//                 تسجيل دخول
//               </NavLink>
//               <NavLink
//                 to="/signup"
//                 className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md transition-colors"
//               >
//                 إنشاء حساب
//               </NavLink>
//             </div>
//           )}
//         </div>
//       </nav>
//     </header>
//   );
// };

// export default Navbar;