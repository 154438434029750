import React, { createContext, useState, useEffect } from 'react';
import {jwtDecode} from 'jwt-decode'; // Make sure to import correctly
import axios from '../services/api';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            const decodedToken = jwtDecode(token);
            const userId = decodedToken.user_id; // extract the user id from the decoded token 

            // Fetch user data from the API
            fetchUserData(userId);
        } else {
            setLoading(false);
        }
    }, []);

    //here i am fetching user data using the id extracted from the token : 
    const fetchUserData = async (userId) => {
        try {
            const response = await axios.get(`/api/users/${userId}/`);
            setUser(response.data);
        } catch (error) {
            console.error('Failed to fetch user data:', error);
        } finally {
            setLoading(false);
        }
    };

    const login = async (username, password) => {
        try {
            const response = await axios.post('/api/token/', { username, password });
            localStorage.setItem('token', response.data.access);
            const decodedToken = jwtDecode(response.data.access);
            const userId = decodedToken.user_id; // Assuming the token contains user_id

            // Fetch user data from the API after login
            await fetchUserData(userId);
        } catch (error) {
            console.error('Login failed:', error);
            throw error;
        }
    };

    const logout = () => {
        localStorage.removeItem('token');
        setUser(null);
    };

    return (
        <AuthContext.Provider value={{ user, loading, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export { AuthContext, AuthProvider };
