//////////////////////////////////                               ////////////////////////////////
//////////////////////////////////                               ////////////////////////////////
//////////////////////////////////                               ////////////////////////////////
//////////////////////////////////                               ////////////////////////////////
//////////////////////////////////     Claude Styled             ////////////////////////////////
//////////////////////////////////                               ////////////////////////////////
//////////////////////////////////                               ////////////////////////////////
//////////////////////////////////                               ////////////////////////////////
//////////////////////////////////                               ////////////////////////////////


import React, { useEffect, useState } from "react";
import api from "../services/api";
import ReactPaginate from "react-paginate";

const EngineerPractices = ({ engineerId }) => {
  const [practices, setPractices] = useState([]);
  const [filterYear, setFilterYear] = useState("");
  const [filterMonth, setFilterMonth] = useState("");
  const [sortYear, setSortYear] = useState("asc");
  const [sortMonth, setSortMonth] = useState("asc");
  const [pageNumber, setPageNumber] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);

  const fetchPractices = (page = 1) => {
    api
      .get(`/api/pro-practices/`, {
        params: {
          engineer_id: engineerId,
          page,
          page_size: itemsPerPage,
          year: filterYear || undefined,
          month: filterMonth || undefined,
          sort_year: sortYear,
          sort_month: sortMonth,
        },
      })
      .then((response) => {
        setPractices(response.data.results);
        setTotalPages(Math.ceil(response.data.count / itemsPerPage));
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    fetchPractices(1);
  }, [engineerId, filterYear, filterMonth, sortYear, sortMonth, itemsPerPage]);

  const handleFilterYearChange = (e) => setFilterYear(e.target.value);
  const handleFilterMonthChange = (e) => setFilterMonth(e.target.value);
  const handleSortYearChange = (e) => setSortYear(e.target.value);
  const handleSortMonthChange = (e) => setSortMonth(e.target.value);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
    fetchPractices(selected + 1);
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h3 className="text-3xl font-bold text-gray-800 mb-6">المزاولات</h3>
      
      <div className="bg-white shadow-md rounded-lg p-6 mb-8">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          <div className="flex flex-col">
            <label className="mb-2 font-semibold text-gray-700">Filter by Year:</label>
            <select
              className="border rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={filterYear}
              onChange={handleFilterYearChange}
            >
              <option value="">All</option>
              {[...new Set(practices.map((p) => p.year))].map((year, index) => (
                <option key={index} value={year}>{year}</option>
              ))}
            </select>
          </div>
          <div className="flex flex-col">
            <label className="mb-2 font-semibold text-gray-700">Filter by Month:</label>
            <select
              className="border rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={filterMonth}
              onChange={handleFilterMonthChange}
            >
              <option value="">All</option>
              {Array.from({ length: 12 }, (_, i) => i + 1).map((month, index) => (
                <option key={index} value={month}>{month}</option>
              ))}
            </select>
          </div>
          <div className="flex flex-col">
            <label className="mb-2 font-semibold text-gray-700">Sort by Year:</label>
            <select
              className="border rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={sortYear}
              onChange={handleSortYearChange}
            >
              <option value="asc">Ascending</option>
              <option value="desc">Descending</option>
            </select>
          </div>
          <div className="flex flex-col">
            <label className="mb-2 font-semibold text-gray-700">Sort by Month:</label>
            <select
              className="border rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={sortMonth}
              onChange={handleSortMonthChange}
            >
              <option value="asc">Ascending</option>
              <option value="desc">Descending</option>
            </select>
          </div>
        </div>
      </div>

      <div className="overflow-x-auto bg-white shadow-md rounded-lg">
        <table className="min-w-full table-auto">
          <thead className="bg-gray-100">
            <tr>
              <th className="px-4 py-2 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">المهندس</th>
              <th className="px-4 py-2 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">السنة</th>
              <th className="px-4 py-2 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">الشهر</th>
              <th className="px-4 py-2 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">نوع الإحتساب</th>
              <th className="px-4 py-2 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">الدخل</th>
              <th className="px-4 py-2 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">حالة التثبيت</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {practices.map((practice, index) => (
              <tr key={index} className={index % 2 === 0 ? "bg-gray-50" : "bg-white"}>
                <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900">
                  {practice.engineer.first_name} {practice.engineer.last_name}
                </td>
                <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900">{practice.year}</td>
                <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900">{practice.month}</td>
                <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900">{practice.work_type.type}</td>
                <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900">{practice.income}</td>
                <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900">
                  <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                    practice.fixing_status ? "bg-green-100 text-green-800" : "bg-red-100 text-red-800"
                  }`}>
                    {practice.fixing_status ? "مثبت" : "غير مثبت"}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="mt-6 flex justify-center">
        <ReactPaginate
          previousLabel={"السابق"}
          nextLabel={"التالي"}
          pageCount={totalPages}
          onPageChange={changePage}
          containerClassName={"flex"}
          previousLinkClassName={"px-3 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"}
          nextLinkClassName={"px-3 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"}
          disabledClassName={"opacity-50 cursor-not-allowed"}
          activeClassName={"bg-blue-500 text-white"}
          pageClassName={"mx-1"}
          pageLinkClassName={"px-3 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"}
          breakClassName={"px-3 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700"}
          forcePage={pageNumber}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
        />
      </div>
    </div>
  );
};

export default EngineerPractices;


















////////////////////////////////////////                                  //////////////////////////////
////////////////////////////////////////                                  //////////////////////////////
//////////////////////////////////////// pagination on front & back       //////////////////////////////
////////////////////////////////////////                                  //////////////////////////////
////////////////////////////////////////                                  //////////////////////////////

// import React, { useEffect, useState } from "react";
// import api from "../services/api";
// import ReactPaginate from "react-paginate";

// const EngineerPractices = ({ engineerId }) => {
//   const [practices, setPractices] = useState([]);
//   const [filterYear, setFilterYear] = useState("");
//   const [filterMonth, setFilterMonth] = useState("");
//   const [sortYear, setSortYear] = useState("asc");
//   const [sortMonth, setSortMonth] = useState("asc");
//   const [pageNumber, setPageNumber] = useState(0);
//   const [itemsPerPage, setItemsPerPage] = useState(10);
//   const [totalPages, setTotalPages] = useState(0);

//   const fetchPractices = (page = 1) => {
//     api
//       .get(`/api/pro-practices/`, {
//         params: {
//           engineer_id: engineerId,
//           page,
//           page_size: itemsPerPage,
//           year: filterYear || undefined,
//           month: filterMonth || undefined,
//           sort_year: sortYear,
//           sort_month: sortMonth,
//         },
//       })
//       .then((response) => {
//         setPractices(response.data.results); // Assuming pagination response includes `results`
//         setTotalPages(Math.ceil(response.data.count / itemsPerPage));
//       })
//       .catch((error) => {
//         console.error("Error fetching data:", error);
//       });
//   };

//   useEffect(() => {
//     fetchPractices(1);
//   }, [engineerId, filterYear, filterMonth, sortYear, sortMonth, itemsPerPage]);

//   const handleFilterYearChange = (e) => {
//     setFilterYear(e.target.value);
//   };

//   const handleFilterMonthChange = (e) => {
//     setFilterMonth(e.target.value);
//   };

//   const handleSortYearChange = (e) => {
//     setSortYear(e.target.value);
//   };

//   const handleSortMonthChange = (e) => {
//     setSortMonth(e.target.value);
//   };

//   const changePage = ({ selected }) => {
//     setPageNumber(selected);
//     fetchPractices(selected + 1);
//   };

//   return (
//     <div>
//       <h3>المزاولات</h3>
//       <div style={{ display: "flex" }}>
//         <div style={{ maxWidth: "150px" }}>
//           <label>Filter by Year:</label>
//         </div>
//         <select
//           style={{ maxWidth: "150px" }}
//           value={filterYear}
//           onChange={handleFilterYearChange}
//         >
//           <option value="">All</option>
//           {[...new Set(practices.map((p) => p.year))].map((year, index) => (
//             <option key={index} value={year}>
//               {year}
//             </option>
//           ))}
//         </select>
//         <label>Filter by Month:</label>
//         <select
//           style={{ maxWidth: "150px" }}
//           value={filterMonth}
//           onChange={handleFilterMonthChange}
//         >
//           <option value="">All</option>
//           {Array.from({ length: 12 }, (_, i) => i + 1).map((month, index) => (
//             <option key={index} value={month}>
//               {month}
//             </option>
//           ))}
//         </select>
//         <label>Sort by Year:</label>
//         <select
//           style={{ maxWidth: "150px" }}
//           value={sortYear}
//           onChange={handleSortYearChange}
//         >
//           <option value="asc">Ascending</option>
//           <option value="desc">Descending</option>
//         </select>
//         <label>Sort by Month:</label>
//         <select
//           style={{ maxWidth: "150px" }}
//           value={sortMonth}
//           onChange={handleSortMonthChange}
//         >
//           <option value="asc">Ascending</option>
//           <option value="desc">Descending</option>
//         </select>
//       </div>
//       <table>
//         <thead>
//           <tr>
//             <th>المهندس</th>
//             <th>السنة</th>
//             <th>الشهر</th>
//             <th>نوع الإحتساب</th>
//             <th>الدخل</th>
//             <th>حالة التثبيت</th>
//           </tr>
//         </thead>
//         <tbody>
//           {practices.map((practice, index) => (
//             <tr key={index}>
//               <td>
//                 {practice.engineer.first_name} {practice.engineer.last_name}
//               </td>
//               <td>{practice.year}</td>
//               <td>{practice.month}</td>
//               <td>{practice.work_type.type}</td>
//               <td>{practice.income}</td>
//               <td>{practice.fixing_status ? "مثبت" : "غير مثبت"}</td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//       <div
//         style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
//       >
//         <ReactPaginate
//           previousLabel={"Previous"}
//           nextLabel={"Next"}
//           pageCount={totalPages}
//           onPageChange={changePage}
//           containerClassName={"paginationBttns"}
//           previousLinkClassName={"previousBttn"}
//           nextLinkClassName={"nextBttn"}
//           disabledClassName={"paginationDisabled"}
//           activeClassName={"paginationActive"}
//           forcePage={pageNumber}
//           pageClassName={"paginationItem"}
//           breakClassName={"paginationBreak"}
//           breakLabel={"..."}
//           marginPagesDisplayed={2}
//           pageRangeDisplayed={5}
//         />
//       </div>
//     </div>
//   );
// };

// export default EngineerPractices;

////////////////////////////////////////                        ///////////////////////////////////////
////////////////////////////////////////                        ///////////////////////////////////////
//////////////////////////////////////// pagination on the front only ///////////////////////////////////////
////////////////////////////////////////                        ///////////////////////////////////////
////////////////////////////////////////                        ///////////////////////////////////////

// import React, { useEffect, useState } from 'react';
// import api from '../services/api';
// import ReactPaginate from 'react-paginate';

// const EngineerPractices = ({ engineerId }) => {
//   const [practices, setPractices] = useState([]);
//   const [sortedPractices, setSortedPractices] = useState([]);
//   const [filterYear, setFilterYear] = useState('');
//   const [filterMonth, setFilterMonth] = useState('');
//   const [sortYear, setSortYear] = useState('asc');
//   const [sortMonth, setSortMonth] = useState('asc');
//   const [pageNumber, setPageNumber] = useState(0);
//   const [itemsPerPage, setItemsPerPage] = useState(10);

//   useEffect(() => {
//     api.get(`/api/pro-practices/`, { params: { engineer_id: engineerId } })
//       .then(response => {
//         const sortedData = response.data.sort((a, b) => {
//           if (a.year < b.year) return -1;
//           if (a.year > b.year) return 1;
//           if (a.month < b.month) return -1;
//           if (a.month > b.month) return 1;
//           return 0;
//         });
//         setPractices(response.data);
//         setSortedPractices(sortedData);
//       })
//       .catch(error => {
//         console.error('Error fetching data:', error);
//       });
//   }, []);

//   const handleFilterYearChange = (e) => {
//     setFilterYear(e.target.value);
//   };

//   const handleFilterMonthChange = (e) => {
//     setFilterMonth(e.target.value);
//   };

//   const handleSortYearChange = (e) => {
//     setSortYear(e.target.value);
//   };

//   const handleSortMonthChange = (e) => {
//     setSortMonth(e.target.value);
//   };

//   const filteredPractices = sortedPractices.filter((practice) => {
//     if (filterYear && practice.year !== parseInt(filterYear)) return false;
//     if (filterMonth && practice.month !== parseInt(filterMonth)) return false;
//     return true;
//   });

//   const pagesVisited = pageNumber * itemsPerPage;

//   const displayPractices = filteredPractices.slice(pagesVisited, pagesVisited + itemsPerPage);

//   const pageCount = Math.ceil(filteredPractices.length / itemsPerPage);

//   const changePage = ({ selected }) => {
//     setPageNumber(selected);
//   };

//   return (
//     <div>
//       <h3>المزاولات</h3>
//       <div>
//       <label>Filter by Year:</label>
//         <select value={filterYear} onChange={handleFilterYearChange}>
//           <option value="">All</option>
//           {practices.map((practice, index) => (
//             <option key={index} value={practice.year}>{practice.year}</option>
//           ))}
//         </select>
//         <label>Filter by Month:</label>
//         <select value={filterMonth} onChange={handleFilterMonthChange}>
//           <option value="">All</option>
//           {Array.from({ length: 12 }, (_, i) => i + 1).map((month, index) => (
//             <option key={index} value={month}>{month}</option>
//           ))}
//         </select>
//         <label>Sort by Year:</label>
//         <select value={sortYear} onChange={handleSortYearChange}>
//           <option value="asc">Ascending</option>
//           <option value="desc">Descending</option>
//         </select>
//         <label>Sort by Month:</label>
//         <select value={sortMonth} onChange={handleSortMonthChange}>
//           <option value="asc">Ascending</option>
//           <option value="desc">Descending</option>
//         </select>
//       </div>
//       <table>
//         <thead>
//           <tr>
//             <th>المهندس</th>
//             <th>السنة</th>
//             <th>الشهر</th>
//             <th>نوع الإحتساب</th>
//             <th>الدخل</th>
//             <th>حالة التثبيت</th>
//           </tr>
//         </thead>
//         <tbody>
//           {displayPractices.map((practice, index) => (
//             <tr key={index}>
//               <td>{practice.engineer.first_name} {practice.engineer.last_name}</td>
//               <td>{practice.year}</td>
//               <td>{practice.month}</td>
//               <td>{practice.work_type.type}</td>
//               <td>{practice.income}</td>
//               <td>{practice.fixing_status ? "مثبت" : "غير مثبت"}</td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//       <ReactPaginate
//         previousLabel={"Previous"}
//         nextLabel={"Next"}
//         pageCount={pageCount}
//         onPageChange={changePage}
//         containerClassName={"paginationBttns"}
//         previousLinkClassName={"previousBttn"}
//         nextLinkClassName={"nextBttn"}
//         disabledClassName={"paginationDisabled"}
//         activeClassName={"paginationActive"}
//       />
//     </div>
//   );
// };

// export default EngineerPractices;

/////////////////////////////////////////////////         //////////////////////////////////////////////
///////////////////////////////////////////////// static table //////////////////////////////////////////////
/////////////////////////////////////////////////         //////////////////////////////////////////////

// import React, { useEffect, useState } from 'react';
// import api from '../services/api';

// const EngineerPractices = ({ engineerId }) => {
//   const [practices, setPractices] = useState([]);

//   useEffect(() => {
//     api.get(`/api/pro-practices/`, { params: { engineer_id: engineerId } })
//       .then(response => {
//         setPractices(response.data);
//       })
//       .catch(error => {
//         console.error('Error fetching data:', error);
//       });
//   }, []);

//   return (
//     <div>
//       <h3>المزاولات</h3>
//       <table>
//         <thead>
//           <tr>
//             <th>المهندس</th>
//             <th>السنة</th>
//             <th>الشهر</th>
//             <th>نوع الإحتساب</th>
//             <th>الدخل</th>
//             <th>حالة التثبيت</th>
//           </tr>
//         </thead>
//         <tbody>
//           {practices.map((practice, index) => (
//             <tr key={index}>
//               <td>{practice.engineer.first_name} {practice.engineer.last_name}</td>
//               <td>{practice.year}</td>
//               <td>{practice.month}</td>
//               <td>{practice.work_type.type}</td>
//               <td>{practice.income}</td>
//               <td>{practice.fixing_status ? "مثبت" : "غير مثبت"}</td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
// };

// export default EngineerPractices;

// import React, { useEffect, useState } from 'react';
// import api from '../services/api';

// const EngineerPractices = ({ engineerId }) => {

//   const [practices, setPractices] = useState([]);

//   useEffect(() => {
//     // Fetch data from an API endpoint
//     // api.get(`/api/practices/`,{params: { engineer_id: engineerId }})
//     api.get(`/api/pro-practices/`,{params: { engineer_id: engineerId }})
//       .then(response => {
//         setPractices(response.data);
//       })
//       .catch(error => {
//         console.error('Error fetching data:', error);
//       });
//   }, []);

//   return (
//     <div>
//       <h3>المزاولات</h3>
//       <table>
//         <thead>
//           <tr>
//             <th>المهندس</th>
//             <th>السنة</th>
//             {/* <th>العمر الهندسي</th> */}
//             <th>الشهر</th>
//             {/* <th>الراتب</th> */}
//             {/* <th>إختصاص</th> */}
//             {/* <th>طبيعة عمل</th> */}
//             {/* <th>أخرى</th> */}
//             {/* <th>المجموع</th> */}
//             <th>نوع الإحتساب</th>
//             <th>الدخل</th>
//             <th>حالة التثبيت</th>
//             {/* <th>الغرامة</th> */}
//             {/* <th>الحد الأدنى</th> */}
//             {/* <th>المبلغ المطلوب</th> */}
//           </tr>
//         </thead>
//         <tbody>
//           {practices.map((practice,index) => (
//             <tr key={index}>
//               <td>{practice.engineer.first_name} {practice.engineer.last_name}</td>
//               <td>{practice.year}</td>
//               {/* <td>{practice.eng_age}</td> */}
//               <td>{practice.month}</td>
//               {/* <td>{practice.salary}</td> */}
//               {/* <td>{practice.specialization}</td> */}
//               {/* <td>{practice.job_nature}</td> */}
//               {/* <td>{practice.other}</td> */}
//               {/* <td>{practice.total}</td> */}
//               <td>{practice.work_type.type}</td>
//               <td>{practice.income}</td>
//               <td>{practice.fixing_status?"مثبت":"غير مثبت"}</td>
//               {/* <td>{practice.fines}</td> */}
//               {/* <td>{practice.minimum}</td> */}
//               {/* <td>{practice.amount_to_pay}</td> */}
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
// };

// export default EngineerPractices;
