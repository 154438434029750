///////////////////////////////                              ////////////////////////////////////////
///////////////////////////////                              ////////////////////////////////////////
///////////////////////////////                              ////////////////////////////////////////
///////////////////////////////     CLAUDE Styled            ////////////////////////////////////////
///////////////////////////////                              ////////////////////////////////////////
///////////////////////////////                              ////////////////////////////////////////
///////////////////////////////                              ////////////////////////////////////////

import React, { useState, useEffect, useMemo } from "react";
import axios from "../services/api";
import { RefreshCw, EyeOff, Eye } from "lucide-react";

const AutoCalculation = ({ engineerId, graduationDate }) => {
  const [professionPractices, setProfessionPractices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [showZeroYields, setShowZeroYields] = useState(false);

  useEffect(() => {
    const fetchProfessionPractices = async () => {
      try {
        console.log(
          "Fetching profession practices for engineer ID:",
          engineerId
        );
        const response = await axios.get(
          `/api/all-pro-practices/?engineer_id=${engineerId}`
        );
        console.log(
          "Profession Practices fetched successfully:",
          response.data
        );
        setProfessionPractices(response.data);
      } catch (error) {
        console.error("Failed to fetch profession practices:", error);
      }
    };

    if (engineerId) {
      fetchProfessionPractices();
    } else {
      console.warn("No engineer ID found, skipping fetchProfessionPractices");
    }
  }, [engineerId]);

  const calculateYields = async () => {
    setLoading(true);
    console.log("Starting calculation process...");
    let results = [];

    for (const practice of professionPractices) {
      const practiceId = practice.id;
      const workTypeId = practice.work_type.id;
      const { year, month } = practice;
      const eng_age = year - new Date(graduationDate).getFullYear() + 1;
      const startMonthStr = `${month < 10 ? `0${month}` : month}-${year}`;

      console.log(
        `Processing practice ID: ${practiceId}, Work Type ID: ${workTypeId}`
      );
      console.log(
        `Calculating for Year: ${year}, Month: ${month}, Engineer Age: ${eng_age}`
      );

      const graduationDateParts = graduationDate.split("-");
      const formattedGraduationDate = `${graduationDateParts[1]}-${graduationDateParts[0]}`;

      const requestData = {
        startMonth: startMonthStr,
        endMonth: startMonthStr,
        graduationDate: formattedGraduationDate,
        engineer_id: engineerId,
      };

      try {
        let response;
        switch (workTypeId) {
          case 1:
            console.log("Calling OfficeCalculationView API");
            response = await axios.post("/api/officecalc/", requestData);
            break;
          case 2:
            console.log("Calling EmployeeCalculationView API");
            response = await axios.post("/api/employeecalc/", requestData);
            break;
          case 3:
            console.log("Calling PrivateSectorCalculationView API");
            response = await axios.post("/api/privatesectorcalc/", requestData);
            break;
          case 4:
            console.log("Calling PledgeCalculationView API");
            response = await axios.post("/api/pledgecalc/", requestData);
            break;
          case 5:
            console.log("Calling AbroadCalculationView API");
            response = await axios.post("/api/abroadcalc/", requestData);
            break;
          default:
            console.error("Invalid work type ID:", workTypeId);
            continue;
        }

        console.log("Calculation result received:", response.data);
        results = [
          ...results,
          ...response.data.data.map((row) => ({
            ...row,
            eng_age: eng_age,
            calculationPeriod: practiceId,
          })),
        ];
      } catch (error) {
        console.error(
          `Failed to calculate yields for work type ${workTypeId}:`,
          error
        );
      }
    }

    setTableData(results);
    setLoading(false);
    console.log("Final Results:", results);
  };

  const totalYields = useMemo(() => {
    return tableData.reduce(
      (acc, row) => acc + parseFloat(row.minimum || 0),
      0
    );
  }, [tableData]);

  const toggleZeroYields = () => {
    setShowZeroYields((prev) => !prev);
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="flex flex-col sm:flex-row justify-between items-center mb-6">
        <button
          onClick={calculateYields}
          className={`bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-4 rounded-lg transition duration-300 ease-in-out transform hover:scale-105 flex items-center ${
            loading ? "opacity-50 cursor-not-allowed" : ""
          }`}
          disabled={loading}
        >
          {loading ? (
            <>
              <RefreshCw className="animate-spin mr-2" size={20} />
              يحسب...
            </>
          ) : (
            "إجراء احتساب تلقائي"
          )}
        </button>

        {tableData.length > 0 && (
          <button
            onClick={toggleZeroYields}
            className="mt-4 sm:mt-0 bg-gray-200 hover:bg-gray-300 text-gray-800 font-semibold py-2 px-4 rounded-lg transition duration-300 ease-in-out flex items-center"
          >
            {showZeroYields ? (
              <>
                <EyeOff className="mr-2" size={20} />
                اخفاء العائدات المساوية للصفر
              </>
            ) : (
              <>
                <Eye className="mr-2" size={20} />
                اظهار العائدات المساوية للصفر
              </>
            )}
          </button>
        )}
      </div>

      {loading && (
        <div className="flex justify-center items-center py-8">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-orange-500"></div>
        </div>
      )}

      {!loading && tableData.length > 0 && (
        <div className="overflow-x-auto shadow-md rounded-lg">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                {[
                  "السنة",
                  "الشهر",
                  "العمر الهندسي",
                  "نوع العمل",
                  "مجموع التوريد",
                  "توريد الشهر",
                  "العائدات",
                  "Flag",
                ].map((header) => (
                  <th
                    key={header}
                    className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {tableData.map((result, index) => {
                if (result.minimum === 0 && !showZeroYields) {
                  return null;
                }

                return (
                  <tr
                    key={index}
                    className={index % 2 === 0 ? "bg-gray-50" : "bg-white"}
                  >
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {result.year}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {result.month}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {result.eng_age}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {result.work_type}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {result.year_income}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {result.month_income}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {result.minimum}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {result.flag} | {result.old_percentage}%
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}

      {!loading && tableData.length > 0 && (
        <div className="mt-6 text-right">
          <p className="text-lg font-semibold text-gray-800">
            مجموع العائدات:{" "}
            <span className="text-orange-600">{totalYields.toFixed(2)}</span>
          </p>
        </div>
      )}
    </div>
  );
};

export default AutoCalculation;

///////////////////////////////                             //////////////////////////
///////////////////////////////                             //////////////////////////
///////////////////////////////                             //////////////////////////
///////////////////////////////   before claude            //////////////////////////
///////////////////////////////                             //////////////////////////
///////////////////////////////                             //////////////////////////
///////////////////////////////                             //////////////////////////

// import React, { useState, useEffect, useMemo } from "react";
// import axios from "../services/api";

// const AutoCalculation = ({ engineerId, graduationDate }) => {
//   const [professionPractices, setProfessionPractices] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [tableData, setTableData] = useState([]);

//   // State to control visibility of zero-yield rows
//   const [showZeroYields, setShowZeroYields] = useState(false);

//   useEffect(() => {
//     const fetchProfessionPractices = async () => {
//       try {
//         console.log("Fetching profession practices for engineer ID:", engineerId);
//         const response = await axios.get(`/api/all-pro-practices/?engineer_id=${engineerId}`);
//         console.log("Profession Practices fetched successfully:", response.data);
//         setProfessionPractices(response.data);
//       } catch (error) {
//         console.error("Failed to fetch profession practices:", error);
//       }
//     };

//     if (engineerId) {
//       fetchProfessionPractices();
//     } else {
//       console.warn("No engineer ID found, skipping fetchProfessionPractices");
//     }
//   }, [engineerId]);

// const calculateYields = async () => {
//   setLoading(true);
//   console.log("Starting calculation process...");
//   let results = [];

//   for (const practice of professionPractices) {
//     const practiceId = practice.id;
//     const workTypeId = practice.work_type.id;
//     const { year, month } = practice;
//     const eng_age = year - new Date(graduationDate).getFullYear() + 1;
//     const startMonthStr = `${month < 10 ? `0${month}` : month}-${year}`;

//     console.log(
//       `Processing practice ID: ${practiceId}, Work Type ID: ${workTypeId}`
//     );
//     console.log(
//       `Calculating for Year: ${year}, Month: ${month}, Engineer Age: ${eng_age}`
//     );

//     const graduationDateParts = graduationDate.split("-");
//     const formattedGraduationDate = `${graduationDateParts[1]}-${graduationDateParts[0]}`;

//     const requestData = {
//       startMonth: startMonthStr,
//       endMonth: startMonthStr,
//       graduationDate: formattedGraduationDate,
//       engineer_id: engineerId,
//     };

//     try {
//       let response;
//       switch (workTypeId) {
//         case 1:
//           console.log("Calling OfficeCalculationView API");
//           response = await axios.post("/api/officecalc/", requestData);
//           break;
//         case 2:
//           console.log("Calling EmployeeCalculationView API");
//           response = await axios.post("/api/employeecalc/", requestData);
//           break;
//         case 3:
//           console.log("Calling PrivateSectorCalculationView API");
//           response = await axios.post("/api/privatesectorcalc/", requestData);
//           break;
//         case 4:
//           console.log("Calling PledgeCalculationView API");
//           response = await axios.post("/api/pledgecalc/", requestData);
//           break;
//         case 5:
//           console.log("Calling AbroadCalculationView API");
//           response = await axios.post("/api/abroadcalc/", requestData);
//           break;
//         default:
//           console.error("Invalid work type ID:", workTypeId);
//           continue;
//       }

//       console.log("Calculation result received:", response.data);
//       results = [
//         ...results,
//         ...response.data.data.map((row) => ({
//           ...row,
//           eng_age: eng_age,
//           calculationPeriod: practiceId,
//         })),
//       ];
//     } catch (error) {
//       console.error(`Failed to calculate yields for work type ${workTypeId}:`, error);
//     }
//   }

//     setTableData(results);
//     setLoading(false);
//     console.log("Final Results:", results);
//   };

//   // Calculate the total yields using useMemo for performance optimization
//   const totalYields = useMemo(() => {
//     return tableData.reduce((acc, row) => acc + parseFloat(row.minimum || 0), 0);
//   }, [tableData]);

//   // Function to toggle the visibility of zero-yield rows
//   const toggleZeroYields = () => {
//     setShowZeroYields((prev) => !prev);
//   };

//   return (
//     <div>
//       <button onClick={calculateYields} className="btn-orange my-3" disabled={loading}>
//         {loading ? "يحسب..." : "إجراء احتساب تلقائي"}
//       </button>

//       {/* Toggle button to show/hide zero-yield rows */}
//       {tableData.length > 0 && (
//         <button onClick={toggleZeroYields} className="btn-secondary my-3 ml-2">
//           {showZeroYields ? "اخفاء العائدات المساوية للصفر" : "اظهار العائدات المساوية للصفر"}
//         </button>
//       )}

//       {loading && <p>يحسب...</p>}

//       {!loading && tableData.length > 0 && (
//         <>
//           <table>
//             <thead>
//               <tr>
//                 <th>السنة</th>
//                 <th>الشهر </th>
//                 <th>العمر الهندسي</th>
//                 <th>نوع العمل</th>
//                 <th>مجموع التوريد</th>
//                 <th>توريد الشهر</th>
//                 <th>العائدات</th>
//                 <th>Flag</th>
//               </tr>
//             </thead>
//             <tbody>
//               {tableData.map((result, index) => {
//                 // Determine if the row should be visible
//                 if (result.minimum === 0 && !showZeroYields) {
//                   return null; // Skip rendering this row
//                 }

//                 return (
//                   <tr
//                     key={index}
//                     style={{
//                       backgroundColor: index % 2 === 0 ? "#e6f4ff" : "#fff",
//                     }}
//                   >
//                     <td>{result.year}</td>
//                     <td>{result.month}</td>
//                     <td>{result.eng_age}</td>
//                     <td>{result.work_type}</td>
//                     <td>{result.year_income}</td>
//                     <td>{result.month_income}</td>
//                     <td>{result.minimum}</td>
//                     <td>{result.flag}  |  {result.old_percentage}% </td>
//                   </tr>
//                 );
//               })}
//             </tbody>
//           </table>

//           {/* Display Total Yields */}
//           <div style={{ marginTop: "20px", fontWeight: "bold" ,direction:"rtl" }}>
//             مجوع العائدات : {totalYields.toFixed(2)}
//           </div>
//         </>
//       )}
//     </div>
//   );
// };

// export default AutoCalculation;

/////////////////////////////////////////////            //////////////////////////////////////////////////////////////
/////////////////////////////////////////////works 100% ////////////////////////////////////////////////
/////////////////////////////////////////////           //////////////////////////////////////////////////////

// import React, { useState, useEffect } from "react";
// import axios from "../services/api";

// const AutoCalculation = ({ engineerId, graduationDate }) => {
//   const [professionPractices, setProfessionPractices] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [tableData, setTableData] = useState([]);

//   // State to control visibility of zero-yield rows
//   const [showZeroYields, setShowZeroYields] = useState(false);

//   useEffect(() => {
//     const fetchProfessionPractices = async () => {
//       try {
//         console.log(
//           "Fetching profession practices for engineer ID:",
//           engineerId
//         );
//         const response = await axios.get(
//           `/api/pro-practices/?engineer_id=${engineerId}`
//         );
//         console.log(
//           "Profession Practices fetched successfully:",
//           response.data
//         );
//         setProfessionPractices(response.data);
//       } catch (error) {
//         console.error("Failed to fetch profession practices:", error);
//       }
//     };

//     if (engineerId) {
//       fetchProfessionPractices();
//     } else {
//       console.warn("No engineer ID found, skipping fetchProfessionPractices");
//     }
//   }, [engineerId]);

//   const calculateYields = async () => {
//     setLoading(true);
//     console.log("Starting calculation process...");
//     let results = [];

//     for (const practice of professionPractices) {
//       const practiceId = practice.id;
//       const workTypeId = practice.work_type.id;
//       const { year, month } = practice;
//       const eng_age = year - new Date(graduationDate).getFullYear() + 1;
//       const startMonthStr = `${month < 10 ? `0${month}` : month}-${year}`;

//       console.log(
//         `Processing practice ID: ${practiceId}, Work Type ID: ${workTypeId}`
//       );
//       console.log(
//         `Calculating for Year: ${year}, Month: ${month}, Engineer Age: ${eng_age}`
//       );

//       const graduationDateParts = graduationDate.split("-");
//       const formattedGraduationDate = `${graduationDateParts[1]}-${graduationDateParts[0]}`;

//       const requestData = {
//         startMonth: startMonthStr,
//         endMonth: startMonthStr,
//         graduationDate: formattedGraduationDate,
//         engineer_id: engineerId,
//       };

//       try {
//         let response;
//         switch (workTypeId) {
//           case 1:
//             console.log("Calling OfficeCalculationView API");
//             response = await axios.post("/api/officecalc/", requestData);
//             break;
//           case 2:
//             console.log("Calling EmployeeCalculationView API");
//             response = await axios.post("/api/employeecalc/", requestData);
//             break;
//           case 3:
//             console.log("Calling PrivateSectorCalculationView API");
//             response = await axios.post("/api/privatesectorcalc/", requestData);
//             break;
//           case 4:
//             console.log("Calling PledgeCalculationView API");
//             response = await axios.post("/api/pledgecalc/", requestData);
//             break;
//           case 5:
//             console.log("Calling AbroadCalculationView API");
//             response = await axios.post("/api/abroadcalc/", requestData);
//             break;
//           default:
//             console.error("Invalid work type ID:", workTypeId);
//             continue;
//         }

//         console.log("Calculation result received:", response.data);
//         results = [
//           ...results,
//           ...response.data.data.map((row) => ({
//             ...row,
//             eng_age: eng_age,
//             calculationPeriod: practiceId,
//           })),
//         ];
//       } catch (error) {
//         console.error(
//           `Failed to calculate yields for work type ${workTypeId}:`,
//           error
//         );
//       }
//     }

//     setTableData(results);
//     setLoading(false);
//     console.log("Final Results:", results);
//   };

//   return (
//     <div>
//       <button onClick={calculateYields} className="btn-orange my-3">
//         Perform Auto Calculation
//       </button>
//       {loading && <p>Calculating...</p>}
//       {!loading && tableData.length > 0 && (

// <>
// <table>
//   <thead>
//     <tr>
//       <th>Year</th>
//       <th>Month</th>
//       <th>Engineer Age</th>
//       <th>Year Income</th>
//       <th>Yield</th>
//     </tr>
//   </thead>
//   <tbody>
//     {tableData.map((result, index) => {
//       // Determine if the row should be visible
//       if (result.minimum === 0 && !showZeroYields) {
//         return null; // Skip rendering this row
//       }

//       return (
//         <tr
//           key={index}
//           style={{
//             backgroundColor: index % 2 === 0 ? "#e6f4ff" : "#fff",
//           }}
//         >
//           <td>{result.year}</td>
//           <td>{result.month}</td>
//           <td>{result.eng_age}</td>
//           <td>{result.year_income}</td>
//           <td>{result.minimum}</td>
//         </tr>
//       );
//     })}
//   </tbody>
// </table>

// {/* Display Total Yields */}
// {/* <div style={{ marginTop: "20px", fontWeight: "bold" }}>
//   Total Yields: {totalYields.toFixed(2)}
// </div> */}
// </>
//         // <table>
//         //   <thead>
//         //     <tr>
//         //       <th>Year</th>
//         //       <th>Month</th>
//         //       <th>Engineer Age</th>
//         //       <th>Year Income</th>
//         //       <th>Yield</th>
//         //     </tr>
//         //   </thead>
//         //   <tbody>
//         //     {tableData.map((result, index) => (
//         //       <tr
//         //         key={index}
//         //         style={{
//         //           backgroundColor: index % 2 === 0 ? "#e6f4ff" : "#fff",
//         //         }}
//         //       >
//         //         <td>{result.year}</td>
//         //         <td>{result.month}</td>
//         //         <td>{result.eng_age}</td>
//         //         <td>{result.year_income}</td>
//         //         <td>{result.minimum}</td>
//         //       </tr>
//         //     ))}
//         //   </tbody>
//         // </table>
//       )}
//     </div>
//   );
// };

// export default AutoCalculation;

// import React, { useState, useEffect } from 'react';
// import useAuth from '../hooks/useAuth';
// import axios from '../services/api';

// const AutoCalculation = () => {
//   const { user } = useAuth();
//   const engineerId = user.engineer ? user.engineer_details.id : null;
//   const graduation_date = user.engineer ? user.engineer_details.graduation_date : null;
//   const graduation_date_obj = new Date(graduation_date);
//   const graduation_year = graduation_date_obj.getFullYear();

//   const [professionPractices, setProfessionPractices] = useState([]);
//   const [tableData, setTableData] = useState([]);
//   const [loading, setLoading] = useState(false);

//   // Fetching the profession practices for the current engineer
//   useEffect(() => {
//     const fetchProfessionPractices = async () => {
//       try {
//         console.log('Fetching profession practices for engineer ID:', engineerId);
//         const response = await axios.get(`/api/pro-practices/?engineer_id=${engineerId}`);
//         console.log('Profession Practices fetched successfully:', response.data);
//         setProfessionPractices(response.data);
//       } catch (error) {
//         console.error('Failed to fetch profession practices:', error);
//       }
//     };

//     if (engineerId) {
//       fetchProfessionPractices();
//     } else {
//       console.warn('No engineer ID found, skipping fetchProfessionPractices');
//     }
//   }, [engineerId]);

//   // Function to calculate the yields based on the fetched practices
//   const calculateYields = async () => {
//     setLoading(true);
//     console.log('Starting calculation process...');
//     let results = [];

//     for (const practice of professionPractices) {
//       const start_date = new Date(practice.start_date);
//       const end_date = new Date(practice.end_date);
//       const workTypeId = practice.work_type;

//       console.log(`Processing practice ID: ${practice.id}, Work Type ID: ${workTypeId}`);
//       let current_date = start_date;

//       while (current_date <= end_date) {
//         const current_year = current_date.getFullYear();
//         const current_month = current_date.getMonth() + 1;
//         const eng_age = (current_year - graduation_year) + 1;
//         const startMonthStr = `${current_month < 10 ? `0${current_month}` : current_month}-${current_year}`;

//         console.log(`Calculating for Year: ${current_year}, Month: ${current_month}, Engineer Age: ${eng_age}`);

//         const requestData = {
//           startMonth: startMonthStr,
//           endMonth: startMonthStr,
//           graduationDate: graduation_date,
//           engineer_id: engineerId,
//         };

//         try {
//           let response;
//           switch (workTypeId) {
//             case 1:
//               console.log('Calling OfficeCalculationView API');
//               response = await axios.post('/api/officecalc/', requestData);
//               break;
//             case 2:
//               console.log('Calling EmployeeCalculationView API');
//               response = await axios.post('/api/employeecalc/', requestData);
//               break;
//             case 3:
//               console.log('Calling PrivateSectorCalculationView API');
//               response = await axios.post('/api/privatesectorcalc/', requestData);
//               break;
//             case 4:
//               console.log('Calling PledgeCalculationView API');
//               response = await axios.post('/api/pledgecalc/', requestData);
//               break;
//             case 5:
//               console.log('Calling AbroadCalculationView API');
//               response = await axios.post('/api/abroadcalc/', requestData);
//               break;
//             default:
//               console.error('Invalid work type ID:', workTypeId);
//               continue;
//           }

//           console.log('Calculation result received:', response.data);
//           results = [
//             ...results,
//             ...response.data.data.map((row) => ({
//               ...row,
//               eng_age: eng_age,
//               calculationPeriod: practice.id,
//             })),
//           ];
//         } catch (error) {
//           console.error(`Failed to calculate yields for work type ${workTypeId}:`, error);
//         }

//         current_date.setMonth(current_date.getMonth() + 1); // Move to the next month
//       }
//     }

//     setTableData(results);
//     setLoading(false);
//     console.log('Final Results:', results);
//   };

//   return (
//     <div>
//       <button onClick={calculateYields} disabled={loading}>
//         {loading ? 'Calculating...' : 'Perform Auto Calculation'}
//       </button>

//       {tableData.length > 0 ? (
//         <table>
//           <thead>
//             <tr>
//               <th>السنة</th>
//               <th>الشهر</th>
//               <th>العمر الهندسي</th>
//               <th>الدخل السنوي</th>
//               <th>الحد الأدنى</th>
//             </tr>
//           </thead>
//           <tbody>
//             {tableData.map((row, index) => (
//               <tr key={index} style={{ backgroundColor: row.calculationPeriod % 2 === 0 ? '#f0f0f0' : '#ffffff' }}>
//                 <td>{row.year}</td>
//                 <td>{row.month}</td>
//                 <td>{row.eng_age}</td>
//                 <td>{row.year_income}</td>
//                 <td>{row.minimum}</td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       ) : (
//         loading && <p>Loading results...</p>
//       )}
//     </div>
//   );
// };

// export default AutoCalculation;
