import React, { useState } from 'react';
import EngineerPractices from './EngineerPractices';
import EngineerCard from '../components/EngineerCard';
import Card from '../components/UI/Card';
import useAuth from '../hooks/useAuth';
import CalculationForm from '../components/CalculationForm';
import AutoCalculation from '../components/AutoCalculation';

const DashboardPage = () => {
  const { user } = useAuth();
  const [showCalc, setShowCalc] = useState(false);

  console.log("Current User:", user);

  return (
    <Card>
      {user.engineer ? (
        <>
          <EngineerCard engineerId={user.engineer_details.id} />
          <EngineerPractices engineerId={user.engineer_details.id} />
          <AutoCalculation
            engineerId={user.engineer_details.id}
            graduationDate={user.engineer_details.graduation_date} // Ensure that the graduation date is available in `user.engineer_details`
          />
          <button onClick={() => setShowCalc(!showCalc)} className="btn-orange my-3">إحتساب</button>
          {showCalc && <CalculationForm />}
        </>
      ) : (
        <h1>user is not attached to any engineer!</h1>
      )}
    </Card>
  );
};

export default DashboardPage;












// import React, { useState } from 'react';
// import EngineerPractices from './EngineerPractices';
// import EngineerCard from '../components/EngineerCard';
// import Card from '../components/UI/Card';

// import useAuth from '../hooks/useAuth';
// import CalculationForm from '../components/CalculationForm';
// import AutoCalculation from '../components/AutoCalculation';

// const DashboardPage = () => {
//   const { user } = useAuth();
//   const [showCalc,setShowCalc]=useState(false);
//   console.log("Current User : ", user);
//   return (
//     <Card>
//       {user.engineer ? (
//         <>
//           <EngineerCard engineerId={user.engineer_details.id} />
//           <EngineerPractices engineerId={user.engineer_details.id} />
//           <AutoCalculation/>
//           <button onClick={()=>setShowCalc(!showCalc)} className="btn-orange my-3">إحتساب</button>
//           {
//             showCalc &&
//             <CalculationForm />
//           }
//         </>
//       ) : (
//         <h1>user is not attached to any engineer !</h1>
//       )
//       }
//     </Card>
//   );
// };

// export default DashboardPage;


