import React, { useState, useEffect, Fragment } from 'react';
import axios from '../services/api';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/flatpickr.min.css'; // Import Flatpickr CSS

const EngineerForm = ({ userId }) => {
    // const [engId, setEngId] = useState(null); 
    const [engineerData, setEngineerData] = useState({
        engineer_number: '',
        central_number: '',
        branch: '',
        first_name: '',
        last_name: '',
        father_name: '',
        mother_name: '',
        graduation_date: '',
        join_date: '',
        home_branch: '',
        date_of_birth: '',
        gender: true,
    });

    const [branches, setBranches] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        axios.get('/api/branches/')
            .then(response => {
                setBranches(response.data);
            })
            .catch(error => {
                console.error("Error fetching branches", error);
            });
    }, []);

    // Custom handler for Flatpickr date changes
    const handleDateChange = (date, dateName) => {
        setEngineerData({ ...engineerData, [dateName]: date.toISOString().split('T')[0] });
    };
    const handleEngineerChange = (e) => {
        const { name, value } = e.target;
        setEngineerData({ ...engineerData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const engineerResponse = await axios.post('/api/engineers/', engineerData);
            // recieve the id from the response that contains the engineer id : 
            const { id } = engineerResponse.data;
            console.log("Engineer details submitted", engineerResponse.data);

            // after creating the engineer , i assigned it to the user with the passed id (userId)
            const userResponse = await axios.patch(`/api/users/${userId}/`, { engineer: id });
            console.log("User updated with engineer ID", userResponse.data);

            // Handle success (e.g., redirect or show success message)
        } catch (error) {
            console.error("There was an error", error);
            setError(error.response ? error.response.data : "Submission failed.");
        }
    };

    return (
        <Fragment>
            {/* <p>{userId}</p> the id of the user created in the previous step  */}
            <form className="form-grid" onSubmit={handleSubmit}>
                <div className='form-group'>
                    <label htmlFor="engineer_number">الرقم الهندسي</label>
                    <input
                        type="text"
                        id="engineer_number"
                        name="engineer_number"
                        value={engineerData.engineer_number}
                        onChange={handleEngineerChange}
                        // placeholder="Engineer Number"
                        required
                    />
                </div>
                <div className='form-group'>
                    <label htmlFor="central_number">الرقم المركزي</label>
                    <input
                        type="text"
                        id="central_number"
                        name="central_number"
                        value={engineerData.central_number}
                        onChange={handleEngineerChange}
                        // placeholder="Central Number"
                        required
                    />
                </div>
                <div className='form-group'>
                    <label htmlFor="branch">الفرع الحالي</label>
                    <select
                        id="branch"
                        name="branch"
                        value={engineerData.branch}
                        onChange={handleEngineerChange}
                        required
                    >
                        <option value="">الفرع الحالي</option>
                        {branches.map(branch => (
                            <option key={branch.id} value={branch.id}>{branch.branch_name}</option>
                        ))}
                    </select>
                </div>
                <div className='form-group'>
                    <label htmlFor="first_name">الاسم</label>
                    <input
                        type="text"
                        id="first_name"
                        name="first_name"
                        value={engineerData.first_name}
                        onChange={handleEngineerChange}
                        // placeholder="First Name"
                        required
                    />
                </div>
                <div className='form-group'>
                    <label htmlFor="last_name">الكنية</label>
                    <input
                        type="text"
                        id="last_name"
                        name="last_name"
                        value={engineerData.last_name}
                        onChange={handleEngineerChange}
                        // placeholder="Last Name"
                        required
                    />
                </div>
                <div className='form-group'>
                    <label htmlFor="father_name">اسم الأب</label>
                    <input
                        type="text"
                        id="father_name"
                        name="father_name"
                        value={engineerData.father_name}
                        onChange={handleEngineerChange}
                        // placeholder="Father Name"
                        required
                    />
                </div>
                <div className='form-group'>
                    <label htmlFor="mother_name">اسم الأم</label>
                    <input
                        type="text"
                        id="mother_name"
                        name="mother_name"
                        value={engineerData.mother_name}
                        onChange={handleEngineerChange}
                        // placeholder="Mother Name"
                        required
                    />
                </div>
                <div className='form-group'>
                    <label htmlFor="graduation_date">تاريخ التخرج</label>
                    <Flatpickr
                        value={engineerData.graduation_date}
                        onChange={([date]) => handleDateChange(date, 'graduation_date')}
                        options={{ dateFormat: 'Y-m-d' }}
                        className='flat-date'
                    />
                    {/* <input
                        type="date"
                        id="graduation_date"
                        name="graduation_date"
                        value={engineerData.graduation_date}
                        onChange={handleEngineerChange}
                        // placeholder="Graduation Date"
                        required
                    /> */}
                </div>
                <div className='form-group'>
                    <label htmlFor="join_date">تاريخ الإنتساب</label>
                    <Flatpickr
                        value={engineerData.join_date}
                        onChange={([date]) => handleDateChange(date, 'join_date')}
                        options={{ dateFormat: 'Y-m-d' }}
                        className='flat-date'
                    />
                    {/* <input
                        type="date"
                        id="join_date"
                        name="join_date"
                        value={engineerData.join_date}
                        onChange={handleEngineerChange}
                        // placeholder="Join Date"
                        required
                    /> */}
                </div>
                <div className='form-group'>
                    <label htmlFor="date_of_birth">تاريخ الميلاد</label>
                    <Flatpickr
                        value={engineerData.date_of_birth}
                        onChange={([date]) => handleDateChange(date, 'date_of_birth')}
                        options={{ dateFormat: 'Y-m-d' }}
                        className='flat-date'
                    />
                    {/* <input
                        type="date"
                        id="date_of_birth"
                        name="date_of_birth"
                        value={engineerData.date_of_birth}
                        onChange={handleEngineerChange}
                        // placeholder="Date of Birth"
                        required
                    /> */}
                </div>
                <div className='form-group'>
                    <label htmlFor="home_branch">الفرع الأم</label>
                    <select
                        id="home_branch"
                        name="home_branch"
                        value={engineerData.home_branch}
                        onChange={handleEngineerChange}
                        required
                    >
                        <option value="">الفرع الأساسي</option>
                        {branches.map(branch => (
                            <option key={branch.id} value={branch.id}>{branch.branch_name}</option>
                        ))}
                    </select>
                </div>
                <div className='form-group'>
                    <label htmlFor="gender">الجنس</label>
                    <select
                        id="gender"
                        name="gender"
                        value={engineerData.gender}
                        onChange={handleEngineerChange}
                    >
                        <option value={true}>ذكر</option>
                        <option value={false}>أنثى</option>
                    </select>
                </div>
                <button type="submit" className='w-full my-5 btn-green'>إضافة مهندس</button>
                {error && <div className='error-message text-center'>{error}</div>}
            </form>
        </Fragment>
    );
};

export default EngineerForm;
