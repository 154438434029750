import React, { Fragment, useState, useEffect, useMemo } from "react";
import {
  Button,
  Input,
  Select,
  DatePicker,
  Table,
  Space,
  Popconfirm,
  Flex,
  Typography,
  Card,
  message,
  Collapse,
  Descriptions,
} from "antd";
import useAuth from "../hooks/useAuth";
import axios from "../services/api";
import PictureList from "./UI/PictureList";
// import 'antd/dist/antd.css';
import "./CalculationForm.css";
import moment from "moment";

const { Option } = Select;

// Predefined list of light and professional colors
// const predefinedColors = [
//   "#e6f7ff", // Light blue
//   "#f9f0ff", // Light purple
//   "#fff7e6", // Light orange
//   "#f6ffed", // Light green
//   "#fff1f0", // Light red
//   "#e8f7ff", // Very light blue
//   "#f4f7ff", // Very light lavender
//   // Add more colors as needed
// ];

const predefinedColors = [
  "#ADD8E6", // Very Light Blue
  "#F7F7F7", // Very Light Grey
  "#C6F7D0", // Very Very Light Green
  "#87CEEB", // Another Grade of Very Light Blue
  "#E5E5EA", // Another Grade of Very Light Grey
  "#C7B8EA", // Very Very Light Purple
  // Add more colors as needed
];

// Function to pick a random color from the predefined list
const generateRandomColor = () => {
  return predefinedColors[Math.floor(Math.random() * predefinedColors.length)];
};

// // Function to generate light and professional random colors
// const generateRandomColor = () => {
//   const letters = "CDEF"; // Use only light color components (higher hex values)
//   let color = "#";
//   for (let i = 0; i < 6; i++) {
//     color += letters[Math.floor(Math.random() * letters.length)];
//   }
//   return color;
// };

const CalculationForm = () => {
  const { user } = useAuth();

  const engineerId = user.engineer ? user.engineer_details.id : null;
  const graduation_date = user.engineer
    ? user.engineer_details.graduation_date
    : null;
  const graduation_date_obj = new Date(graduation_date);
  const graduate_month = graduation_date_obj.getMonth() + 1;
  const graduate_year = graduation_date_obj.getFullYear();
  const graduation_date_str = `${
    graduate_month < 10 ? `0${graduate_month}` : graduate_month
  }-${graduate_year}`;

  const [engineerNumber, setEngineerNumber] = useState("");
  const [engineerData, setEngineerData] = useState(null);

  const [workTypes, setWorkTypes] = useState([]);
  const [items, setItems] = useState([]);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [selectedWorkType, setSelectedWorkType] = useState("");
  const [employeeType, setEmployeeType] = useState("");
  const [employeeType_cType, setEmployeeType_cType] = useState("default");
  const [employeeType_dType, setEmployeeType_dType] = useState("default");

  const [officeType, setOfficeType] = useState("");

  const [freeType, setFreeType] = useState("");

  const [abroadType, setAbroadType] = useState("");

  const [militaryType, setMilitaryType] = useState("");
  const [militaryType_aType, setMilitaryType_aType] = useState("default");

  const [additionalField, setAdditionalField] = useState(0); // State for the additional field value

  const [yearlyAdditionalFields, setYearlyAdditionalFields] = useState([]);

  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    const fetchWorkTypes = async () => {
      try {
        const response = await axios.get("/api/worktypes/");
        setWorkTypes(response.data);
      } catch (error) {
        console.error("Failed to fetch work types:", error);
      }
    };
    fetchWorkTypes();
  }, []);

  // Calculate the years between the selected start and end dates
  const calculateYearsInRange = () => {
    if (startDate && endDate) {
      const startYear = startDate.year();
      const endYear = endDate.year();
      const years = [];

      for (let year = startYear; year <= endYear; year++) {
        years.push({
          year,
          additionalField: "", // Each year has its own additionalField value
        });
      }

      setYearlyAdditionalFields(years);
    }
  };

  // Trigger the calculation of years when startDate or endDate is changed
  useEffect(() => {
    calculateYearsInRange();
  }, [startDate, endDate]);

  const handleYearInputChange = (year, value) => {
    setYearlyAdditionalFields((prev) =>
      prev.map((item) =>
        item.year === year ? { ...item, additionalField: value } : item
      )
    );
    console.log("Yearly Additional Fields : ", yearlyAdditionalFields);
  };

  const handleSearch = async () => {
    try {
      const response = await axios.get(
        `/api/engineers/central_number/${engineerNumber}/`
      );
      setEngineerData(response.data); // Set the retrieved engineer data
      message.success("Engineer found!");
      setTableData([]);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        message.error("Engineer not found!");
      } else {
        message.error("An error occurred while fetching the engineer.");
      }
      setEngineerData(null); // Clear the engineer data if not found
    }
  };

  const handleAddItem = () => {
    setTableData([]);
    console.log("startDate: ", startDate);
    console.log("endDate: ", endDate);
    console.log("selectedWorkType: ", selectedWorkType);
    console.log("officeType: ", officeType);
    console.log("yearlyAdditionalFields: ", yearlyAdditionalFields);

    if (
      startDate &&
      endDate &&
      selectedWorkType &&
      officeType == "office_a" &&
      yearlyAdditionalFields.length > 0
    ) {
      const newItems = yearlyAdditionalFields.map((yearItem) => ({
        year: yearItem.year,
        additionalField: yearItem.additionalField || 0,
        startDate:
          yearItem.year === startDate.year
            ? startDate.format("YYYY-MM-DD")
            : moment(`${yearItem.year}-01-01`).format("YYYY-MM-DD"),
        endDate:
          yearItem.year === endDate.year
            ? endDate.format("YYYY-MM-DD")
            : moment(`${yearItem.year}-12-31`).format("YYYY-MM-DD"),
        workType: selectedWorkType,
        officeType: officeType,
      }));

      setItems([...items, ...newItems]);
      setStartDate(null);
      setEndDate(null);
      setSelectedWorkType("");
      setOfficeType("");
      setYearlyAdditionalFields([]);
      console.log("CASE_2 ----> Items : ", items);
    } else if (startDate && endDate && selectedWorkType) {
      setItems([
        ...items,
        {
          startDate: startDate.format("YYYY-MM-DD"),
          endDate: endDate.format("YYYY-MM-DD"),
          workType: selectedWorkType,
          employeeType: employeeType,
          employeeType_cType: employeeType_cType,
          employeeType_dType: employeeType_dType,
          officeType: officeType,
          freeType: freeType,
          abroadType: abroadType,
          militaryType: militaryType,
          militaryType_aType: militaryType_aType,
          additionalField: additionalField || 0,
        },
      ]);
      console.log("Adddddddddddddddddddithional :  ", additionalField);
      console.log("CASE_1 ----> Items : ", items);
      setStartDate(null);
      setEndDate(null);
      setSelectedWorkType("");
      setEmployeeType("");
      setEmployeeType_cType("default");
      setEmployeeType_dType("default");
      setOfficeType("");
      setFreeType("");
      setAbroadType("");
      setMilitaryType("");
      setMilitaryType_aType("default");
      setAdditionalField(0); // Reset additional field after adding
      console.log("CASE_1 ----> Items : ", items);
    }

    console.log("###### Items : ", items);
  };

  const handleRemoveItem = (index) => {
    setItems(items.filter((_, i) => i !== index));
  };

  // Extract the graduation date and format it to MM-YYYY
  const formatGraduationDate = (date) => {
    const dateObj = new Date(date);
    const month = ("0" + (dateObj.getMonth() + 1)).slice(-2); // Get the month and pad with leading zero
    const year = dateObj.getFullYear();
    return `${month}-${year}`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Format the date before making the request
    const formattedGraduationDate = formatGraduationDate(
      engineerData.graduation_date
    );

    for (const item of items) {
      const requestData = {
        startMonth: moment(item.startDate).format("MM-YYYY"), // Format start date as MM-YYYY
        endMonth: moment(item.endDate).format("MM-YYYY"), // Format end date as MM-YYYY
        // graduationDate: graduation_date_str,
        graduationDate: formattedGraduationDate,
        engineer_id: engineerId,
        additionalField: item.additionalField || 0, // Ensure additionalField is provided
        employeeType: item.employeeType || "", // Ensure employeeType is provided
        employeeType_cType: item.employeeType_cType || "", // Ensure employeeType_cType is provided
        employeeType_dType: item.employeeType_dType || "", // Ensure employeeType_dType is provided
        officeType: item.officeType || "", // Ensure officeType is provided
        freeType: item.freeType || "", // Ensure freeType is provided
        abroadType: item.abroadType || "",
        militaryType: item.militaryType || "",
        militaryType_aType: item.militaryType_aType || "",
      };

      console.log("item requstData : ", requestData);

      try {
        let response;
        if (item.workType == 1) {
          response = await axios.post("/api/officecalc2/", requestData);
        } else if (item.workType == 2) {
          response = await axios.post("/api/employeecalc2/", requestData);
        } else if (item.workType == 3) {
          response = await axios.post("/api/privatesectorcalc2/", requestData);
        } else if (item.workType == 5) {
          response = await axios.post("/api/abroadcalc2/", requestData);
        } else if (item.workType == 6) {
          response = await axios.post("/api/militarycalc2/", requestData);
        }

        // setTableData(() => [...response.data.data]);
        setTableData((prevData) => [...prevData, ...response.data.data]);
      } catch (error) {
        console.error(
          `Failed to fetch data for work type ${item.workType}:`,
          error
        );
      }
    }
    // Empty the items array
    // items = [];
  };

  const columns = [
    { title: "من تاريخ", dataIndex: "startDate", key: "startDate" },
    { title: "إلى تاريخ", dataIndex: "endDate", key: "endDate" },
    { title: "نوع العمل", dataIndex: "workType", key: "workType" },
    {
      title: "الحالة",
      dataIndex: "employeeType",
      key: "employeeType",
      render: (text, record) => (
        <span>
          {record.employeeType} | {record.officeType} | {record.militaryType} |{" "}
          {record.freeType}
        </span>
      ),
    },
    {
      title: "نوع الحالة",
      dataIndex: "employeeType_cType",
      key: "employeeType_cType",
    },
    {
      title: "الراتب | التوريد",
      dataIndex: "additionalField",
      key: "additionalField",
    },
    {
      title: "إزالة",
      key: "action",
      render: (_, record, index) => (
        <Popconfirm
          title="هل أنت متأكد من حذف هذا العنصر؟"
          onConfirm={() => handleRemoveItem(index)}
        >
          <a>إزالة</a>
        </Popconfirm>
      ),
    },
  ];

  // const workTypeText = {
  //   1: "مكاتب",
  //   2: "موظف",
  //   3: "عمل حر",
  //   4: "تعهدات",
  //   5: "خارج القطر",
  // };

  const tableColumns = [
    { title: "السنة", dataIndex: "year", key: "year" },
    { title: "الشهر", dataIndex: "month", key: "month" },
    { title: "العمر الهندسي", dataIndex: "eng_age", key: "eng_age" },
    {
      title: "الدخل السنوي",
      dataIndex: "year_income",
      key: "year_income",
      render: (text, record) => (
        <span>
          {record.year_income} | 6% = {(record.year_income * 6) / 100}
        </span>
      ),
    },
    { title: "العائدات", dataIndex: "minimum", key: "minimum" },
    {
      title: "minimum",
      dataIndex: "year_minimum",
      key: "year_minimum",
      render: (text, record) => (
        <span>
          {record.year_minimum} | {record.present_year_minimum}
        </span>
      ),
    },
    {
      title: "fine_percent",
      dataIndex: "fine_percentage",
      key: "fine_percentage",
      render: (text, record) => (
        <span>{parseInt(record.fine_percentage, 10)}%</span>
      ),
    },

    {
      title: "income_percentage",
      dataIndex: "old_percentage",
      key: "old_percentage",
      render: (text, record) => (
        <span>{parseInt(record.old_percentage, 10)}%</span>
      ),
    },
    {
      title: "flag",
      dataIndex: "flag",
      key: "flag",
      render: (text, record) => {
        let flagText = "";
        if (record.month == 12) {
          switch (record.flag) {
            case 0:
              flagText = "";
              break;
            case 1:
              flagText = "لا يوجد توريدات";
              break;
            case 2:
              flagText = "التوريد أقل من الحد الأدنى";
              break;
            case 3:
              flagText = "التوريد أكثر من الحد الأدنى";
              break;
            case 4:
              flagText = "موظف لم تقم دائرته بالحسم,الراتب أعلى";
              break;
            case 5:
              flagText = "قطاع خاص مسجل بالتأمينات,الراتب أعلى";
              break;
            case 6:
              flagText = "موظف لم تقم دائرته بالحسم,العمر الهندسي أعلى";
              break;
            case 7:
              flagText = "قطاع خاص مسجل بالتأمينات,العمر الهندسي أعلى";
              break;
            case 8:
              flagText = "إجازة,قطاع خاص,الراتب أعلى";
              break;
            case 9:
              flagText = "إجازة,قطاع خاص,العمر الهندسي أعلى";
              break;
            case 10:
              flagText = "قبل الانتساب,بريء الذمة";
              break;
            case 11:
              flagText = "قبل الانتساب ,نقص في العائدات المحولة";
              break;
            case 12:
              flagText = "قبل الانتساب,عدم تحويل العائدات للخزانة";
              break;
            case 13:
              flagText = "إجازة,عمل حر,الراتب أعلى";
              break;
            case 14:
              flagText = "إجازة,صاحب منشأة,الراتب أعلى";
              break;
            case 15:
              flagText = "إجازة,عمل حر,العمر الهندسي أعلى";
              break;
            case 16:
              flagText = "إجازة,صاحب منشأة,العمر الهندسي أعلى";
              break;
            case 17:
              flagText = "إجازة,خارج القطر";
              break;
            case 18:
              flagText = "عمل حر ,قبل الانتساب";
              break;
            case 19:
              flagText = "عمل حر ,بعد الانتساب";
              break;
            case 20:
              flagText = "خارج القطر,منتهية اقامته";
              break;
            case 21:
              flagText = " خارج القطر ,مقيم حالياََ في بلد الاغتراب";
              break;
            case 22:
              flagText = " خدمة علم";
              break;
            default:
              flagText = ""; // or some default text
          }
        }
        return <span>{flagText}</span>;
      },
    },
    { title: "type", dataIndex: "type", key: "type" },
  ];

  // const updatedTableData = tableData.map((row) => {
  //   // Ensure minimum is a number before any operation
  //   const minimumValue = Number(row.minimum);

  //   if (
  //     row.type === "office_a" ||
  //     row.type === "abroad_a" ||
  //     row.type === "abroad_b"
  //   ) {
  //     if (row.month >= 1 && row.month <= 11) {
  //       return { ...row, minimum: 0 };
  //     } else if (row.month == 12) {
  //       return { ...row, minimum: minimumValue * 12 };
  //     }
  //   }
  //   return row;
  // });

  const updatedTableData = tableData.map((row) => {
    // Ensure minimum is a number before any operation
    const minimumValue = Number(row.minimum);

    // Find all rows for the current year
    const yearRows = tableData.filter((data) => data.year === row.year);

    // Check if the year has all 12 months
    const hasAllMonths = yearRows.length === 12;

    if (
      row.type === "office_a" ||
      row.type === "abroad_a" ||
      row.type === "abroad_b"
    ) {
      if (hasAllMonths) {
        if (row.month >= 1 && row.month <= 11) {
          return { ...row, minimum: 0 };
        } else if (row.month == 12) {
          return { ...row, minimum: minimumValue * 12 };
        }
      }
    }

    // If the year doesn't have all 12 months, return the original row
    return row;
  });

  const totalYields = useMemo(() => {
    return updatedTableData.reduce(
      (acc, row) => acc + parseFloat(row.minimum || 0),
      0
    );
  }, [updatedTableData]);

  // Map each year to a unique random color
  const yearColorMap = useMemo(() => {
    const colorMap = {};
    updatedTableData.forEach((row) => {
      if (!colorMap[row.year]) {
        colorMap[row.year] = generateRandomColor();
      }
    });
    return colorMap;
  }, [updatedTableData]);

  return (
    <Fragment>
      {items.length > 0 && (
        <div>
          <h3>الأنشطة:</h3>
          <Table
            dataSource={items}
            columns={columns}
            rowKey={(record) => record.startDate + record.endDate}
            pagination={false}
            size="small"
          />
          <hr />
        </div>
      )}

      <h1>احتساب :</h1>
      <form onSubmit={handleSubmit}>
        <Space direction="vertical" size="large" style={{ width: "100%" }}>
          <Flex>
            <div>
              <Space>
                <Typography.Text strong style={{ marginRight: "1rem" }}>
                  رقم المركزي:
                </Typography.Text>
                <Input
                  value={engineerNumber}
                  onChange={(e) => setEngineerNumber(e.target.value)}
                  onPressEnter={handleSearch} // Trigger search on pressing Enter
                  style={{ width: 200 }}
                />
                <Typography.Text type="secondary">
                  اختر احد هذه الارقام (1,2,3,4,5,6,7,8,9,10,12)
                </Typography.Text>
              </Space>

              {engineerData && (
                <Collapse
                  defaultActiveKey={["1"]}
                  style={{ marginBottom: "1rem" }}
                >
                  <Collapse.Panel header="معلومات المهندس" key="1">
                    <Descriptions size="small" column={1}>
                      <Descriptions.Item label="الرقم المركزي">
                        {engineerData.central_number}
                      </Descriptions.Item>
                      <Descriptions.Item label="الرقم النقابي">
                        {engineerData.engineer_number}
                      </Descriptions.Item>
                      <Descriptions.Item label="الفرع">
                        {engineerData.branch_detail
                          ? engineerData.branch_detail.branch_name
                          : engineerData.branch}
                      </Descriptions.Item>
                      <Descriptions.Item label="الفرع الرئيسي">
                        {engineerData.home_branch_detail
                          ? engineerData.home_branch_detail.branch_name
                          : engineerData.home_branch}
                      </Descriptions.Item>
                      <Descriptions.Item label="الاسم والكنية">
                        {engineerData.first_name} {engineerData.last_name}
                      </Descriptions.Item>
                      <Descriptions.Item label="اسم الأب">
                        {engineerData.father_name}
                      </Descriptions.Item>
                      <Descriptions.Item label="اسم الأم">
                        {engineerData.mother_name}
                      </Descriptions.Item>
                      <Descriptions.Item label="تاريخ التخرج">
                        {engineerData.graduation_date}
                      </Descriptions.Item>
                      <Descriptions.Item label="تاريخ الانتساب">
                        {engineerData.join_date}
                      </Descriptions.Item>
                      <Descriptions.Item label="تاريخ الميلاد">
                        {engineerData.date_of_birth}
                      </Descriptions.Item>
                      <Descriptions.Item label="الجنس">
                        {engineerData.gender ? "ذكر" : "أنثى"}
                      </Descriptions.Item>
                    </Descriptions>
                  </Collapse.Panel>
                </Collapse>
              )}
            </div>
          </Flex>
          <Flex>
            <Space>
              <Typography.Text strong>من تاريخ:</Typography.Text>
              <DatePicker
                value={startDate}
                onChange={setStartDate}
                format={{
                  format: "YYYY-MM-DD",
                  type: "mask",
                }}
              />
            </Space>

            <Space>
              <Typography.Text strong style={{ marginRight: "1rem" }}>
                إلى تاريخ:
              </Typography.Text>
              <DatePicker
                value={endDate}
                onChange={setEndDate}
                format={{
                  format: "YYYY-MM-DD",
                  type: "mask",
                }}
              />
            </Space>
          </Flex>

          <Flex>
            <Space>
              <Typography.Text strong>نوع العمل :</Typography.Text>
              <Select
                value={selectedWorkType}
                onChange={setSelectedWorkType}
                style={{ width: 200 }}
              >
                <Option value="">اختر نوع العمل</Option>
                {workTypes.map((workType) => (
                  <Option key={workType.id} value={workType.id}>
                    {workType.type}
                  </Option>
                ))}
              </Select>
            </Space>

            {console.log("selected work type : ", selectedWorkType)}

            <div style={{ maxWidth: "600px" }}>
              {selectedWorkType == 6 && (
                <Space>
                  <Typography.Text strong style={{ marginRight: "1rem" }}>
                    الفترة:
                  </Typography.Text>
                  <Select
                    value={militaryType}
                    onChange={setMilitaryType}
                    style={{ width: 375 }}
                  >
                    <Option value="">اختر الفترة </Option>
                    <Option value="military_a">قبل الانتساب</Option>
                    <Option value="military_b">بعد الانتساب</Option>
                  </Select>
                </Space>
              )}
              {selectedWorkType == 6 && militaryType == "military_a" && (
                <Space style={{ marginTop: "15px" }}>
                  <Typography.Text strong style={{ marginRight: "1rem" }}>
                    الحالة:
                  </Typography.Text>
                  <Select
                    value={militaryType_aType}
                    onChange={setMilitaryType_aType}
                    style={{ width: 375 }}
                  >
                    <Option value="default">اختر الحالة </Option>
                    <Option value="military_a_a">
                      خدمة علم بعد التخرج مباشرة تلاها عمل وظيفي
                    </Option>
                    <Option value="military_a_b">
                      خدمة علم بعد التخرج مباشرة تلاها عمل حر
                    </Option>
                    <Option value="military_a_c">
                      خدمة علم قبل وبعد الانتساب
                    </Option>
                  </Select>
                </Space>
              )}
            </div>

            <div style={{ maxWidth: "600px" }}>
              {selectedWorkType == 5 && (
                <Space>
                  <Typography.Text strong style={{ marginRight: "1rem" }}>
                    الحالة:
                  </Typography.Text>
                  <Select
                    value={abroadType}
                    onChange={setAbroadType}
                    style={{ width: 375 }}
                  >
                    <Option value="">اختر الحالة </Option>
                    <Option value="abroad_a">المهندس المنتهية إقامته</Option>
                    <Option value="abroad_b">
                      المهندس المقيم حايا في بلد الاغتراب{" "}
                    </Option>
                  </Select>
                </Space>
              )}
            </div>

            <div style={{ maxWidth: "600px" }}>
              {selectedWorkType == 3 && (
                <Space>
                  <Typography.Text strong style={{ marginRight: "1rem" }}>
                    الحالة:
                  </Typography.Text>
                  <Select
                    value={freeType}
                    onChange={setFreeType}
                    style={{ width: 375 }}
                  >
                    <Option value="">اختر الحالة </Option>
                    <Option value="free_a">قبل الانتساب</Option>
                    <Option value="free_b">بعد الانتساب </Option>
                  </Select>
                </Space>
              )}
            </div>

            {/* <div style={{ maxWidth: "600px" }}> */}
            {selectedWorkType == 1 && (
              <Space>
                <Typography.Text strong style={{ marginRight: "1rem" }}>
                  حالة صاحب المكتب:
                </Typography.Text>
                <Select
                  value={officeType}
                  onChange={setOfficeType}
                  style={{ width: 375 }}
                >
                  <Option value="">اختر حالة صاحب المكتب</Option>
                  <Option value="office_a">مهندس له توريدات</Option>
                  <Option value="office_b">مهندس ليس له توريدات </Option>
                </Select>
              </Space>
            )}
            {/* </div> */}

            <div style={{ maxWidth: "600px" }}>
              {selectedWorkType == 2 && (
                <Space>
                  <Typography.Text strong style={{ marginRight: "1rem" }}>
                    حالة الموظف:
                  </Typography.Text>
                  <Select
                    value={employeeType}
                    onChange={setEmployeeType}
                    style={{ width: 375 }}
                  >
                    <Option value="">اختر نوع الموظف</Option>
                    <Option value="employee_d">
                      موظف فترة ما قبل الانتساب{" "}
                    </Option>
                    <Option value="employee_a">
                      موظف لم تقم دائرته بحسم العائدات التقاعدية عنه
                    </Option>
                    <Option value="employee_b">
                      موظف لدى القطاع الخاص مسجل بالتأمينات
                    </Option>
                    <Option value="employee_c">
                      موظف حاصل على اجازة بلا اجر
                    </Option>
                  </Select>
                </Space>
              )}

              {selectedWorkType == 2 && employeeType === "employee_d" && (
                <Space style={{ marginTop: "15px" }}>
                  <Typography.Text strong style={{ marginRight: "1rem" }}>
                    الحالة:
                  </Typography.Text>
                  <Select
                    value={employeeType_dType}
                    onChange={setEmployeeType_dType}
                    style={{ width: 350 }}
                  >
                    <Option value="default">اختر الحالة</Option>
                    <Option value="employee_d_a">
                      في حال وجود أمانات لدى خزانة التقاعد باسم المهندس عن كامل
                      المدة المراد تثبيتها
                    </Option>
                    <Option value="employee_d_b">
                      في حال وجود نقص في العائدات المحولة من الجهة التي يعمل بها{" "}
                    </Option>
                    <Option value="employee_d_c">
                      في حال عدم تحويل العائدات للخزانة من قبل الجهة التي يعمل
                      بها{" "}
                    </Option>
                  </Select>
                </Space>
              )}

              {selectedWorkType == 2 && employeeType === "employee_c" && (
                <Space style={{ marginTop: "15px" }}>
                  <Typography.Text strong style={{ marginRight: "1rem" }}>
                    نوع العمل فترة الإجازة:
                  </Typography.Text>
                  <Select
                    value={employeeType_cType}
                    onChange={setEmployeeType_cType}
                    style={{ width: 350 }}
                  >
                    <Option value="default">اختر نوع العمل</Option>
                    <Option value="employee_c_a">عمل حر</Option>
                    <Option value="employee_c_b">
                      عمل لدى القطاع الخاص وكان مسجلا لدى التأمينات
                    </Option>
                    <Option value="employee_c_c">
                      عمل لدى القطاع الخاص وهو مسجل بالتأمينات ويمتلك
                      المنشأة,صاحب المنشأة
                    </Option>
                    <Option value="employee_c_d">خارج القطر</Option>
                  </Select>
                </Space>
              )}
            </div>

            {(selectedWorkType == 2 &&
              !(employeeType_dType === "employee_d_a" ||
                employeeType_dType === "employee_d_b" ||
                employeeType_dType === "employee_d_c") &&
              !(employeeType_cType === "employee_c_d")) ? (
              <Space>
                <Typography.Text strong style={{ marginRight: "1rem" }}>
                  الراتب:
                </Typography.Text>
                <Input
                  type="number"
                  placeholder=" أدخل الراتب الشهري خلال الفترة المحددة"
                  value={additionalField}
                  onChange={(e) => setAdditionalField(e.target.value)}
                  style={{ width: 270 }}
                />
              </Space>
            ) : null}

            {/* {((selectedWorkType == 1 && officeType === "office_a") ||
              selectedWorkType == 4) && (
              <Space>
                <Typography.Text strong style={{ marginRight: "1rem" }}>
                  التوريد:
                </Typography.Text>
                <Input
                  type="number"
                  placeholder="ادخل مجموع التوريدات للفترة المحددة"
                  value={additionalField}
                  onChange={(e) => setAdditionalField(e.target.value)}
                  style={{ width: 270 }}
                />
              </Space>
            )} */}
            {selectedWorkType == 1 && officeType === "office_a" && (
              <Space>
                <Typography.Text strong style={{ marginRight: "1rem" }}>
                  السنوات المحددة:
                </Typography.Text>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  {yearlyAdditionalFields.map((yearItem) => (
                    <Space
                      key={yearItem.year}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Typography.Text strong>{yearItem.year}</Typography.Text>
                      <Input
                        placeholder="أدخل التوريد"
                        value={yearItem.additionalField}
                        onChange={(e) =>
                          handleYearInputChange(yearItem.year, e.target.value)
                        }
                        style={{ marginLeft: "10px" }}
                      />
                    </Space>
                  ))}
                </div>
              </Space>
            )}
          </Flex>

          <Flex>
            <Space>
              <Typography.Text strong>الوثائق المطلوبة:</Typography.Text>
              <PictureList />
              <Typography.Text strong style={{ marginRight: "2rem" }}>
                تفاصيل :
              </Typography.Text>
              <Card style={{ height: "100px", width: "700px" }}>
                {selectedWorkType == 2 && employeeType === "employee_a" && (
                  <div>
                    <p>
                      - بيان مزاولة بالمدة المراد تثبيتها مصدق من الدائرة التي
                      يعمل بها ومن فرع النقابة.
                    </p>
                    <p>- بيان بالإجازات ان وجدت. </p>
                    <p>- بيان بالراتب مع التعويضات.</p>
                  </div>
                )}

                {selectedWorkType == 2 && employeeType === "employee_b" && (
                  <div>
                    <p>- بيان مزاولة مختوم من الشركة التي يعمل بها.</p>
                    <p>- بيان بالراتب الذي يتقاضاه. </p>
                    <p>- وثيقة من التأمينات الاجتماعية يبين فيها اشتراكه.</p>
                  </div>
                )}

                {selectedWorkType == 2 && employeeType === "employee_c" && (
                  <div>
                    <p>
                      - بيان مزاولة المهنة بالمدة المطلوبة مبيناََ فيه مدة
                      الإجازة وتاريخها مصدقاََ من دائرته ومن الفرع المسجل فيه.
                    </p>
                    <p>
                      - جواز سفره أو كتاب من إدارة الهجرة والجوازات يبين فيه عدم
                      مغادرته القطر خلال هذه الفترة.{" "}
                    </p>
                    <p>
                      - كتاب يوضح فيه المهندس العمل الذي قام به أثناء الإجازة
                      معتمداََ بقرار من مجلس الفرع.
                    </p>
                  </div>
                )}

                {selectedWorkType == 2 && employeeType === "employee_d" && (
                  <div>
                    <p>
                      - بيان مزاولة بالمدة المطلوبة من الجهة التي يعمل بها ومصدق
                      من الفرع.
                    </p>
                    <p>- تصوير دفتر خدمة العلم إذا كانت ضمن فترة الضم.</p>
                    <p>
                      - وثيقة من التأمينات الاجتماعية للمسجلين لدى القطاع الخاص.
                    </p>
                    <p>- كتاب من الجهة التي يعمل بها مصرح به راتبه الشهري.</p>
                  </div>
                )}

                {selectedWorkType == 1 && officeType === "office_a" && (
                  <div>
                    <p>
                      - بيان مزاولة المهنة بالمدة المراد تثبيتها مصدق أصولاََ من
                      لجنة المكاتب الخاصة في الفرع.
                    </p>
                    {/* <p>- بيان بالإجازات ان وجدت. </p>
                    <p>- بيان بالراتب مع التعويضات.</p> */}
                  </div>
                )}
                {selectedWorkType == 1 && officeType === "office_b" && (
                  <div>
                    <p>
                      - بيان مزاولة المهنة بالمدة المراد تثبيتها مصدق أصولاََ من
                      لجنة المكاتب الخاصة في الفرع.
                    </p>
                    <p>
                      - جواز سفر يتضمن المدة المراد تثبيتها أو كتاب من إدارة
                      الهجرة والجوازات يبين فيه عدم مغادرته القطر.{" "}
                    </p>
                  </div>
                )}
                {selectedWorkType == 3 && (
                  <div>
                    <p>
                      - بيان مزاولة المهنة بالمدة المراد تثبيتها مصدق أصولاََ من
                      الفرع.
                    </p>
                    <p>
                      - كتاب من الجهة التي عمل لديها مبيناََ فيه رواتبه التي
                      تقاضاها.
                    </p>
                    <p>- مذكرة إدارية باعتماد هذه المدة من قبل مجلس الفرع .</p>
                    <p>
                      - جواز سفره أو كتاب من إدارة الهجرة والجوازات عن هذه
                      المدة.
                    </p>
                    <p>
                      - وثيقة من التأمينات الاجتماعية توضح بأنه غير مسجل لديها.
                    </p>
                  </div>
                )}
                {selectedWorkType == 5 && abroadType === "abroad_a" && (
                  <div>
                    <p>
                      - تصريح بالفترة المطلوب تثبيتها التي قضاها خارج القطر.
                    </p>
                    <p>- وثيقة من الجهة التي كان يعمل فيها.</p>
                  </div>
                )}
                {selectedWorkType == 5 && abroadType === "abroad_b" && (
                  <div>
                    <p>
                      - تصريح بالفترة المطلوب تثبيتها التي قضاها خارج القطر.
                    </p>
                    <p>- وثيقة من الجهة التي كان يعمل فيها.</p>
                    <p>
                      - كتاب بالمدة المراد تثبيتها مصدق من الجهة التي عمل بها في
                      مزاولة المهنة ومن السفارة السورية في بلد الاغتراب
                    </p>
                  </div>
                )}
                {selectedWorkType == 6 && (
                  <div>
                    <p>- بيان مزاولة بالمدة المراد تثبيتها.</p>
                    <p>- إشعار ببراءة ذمة المهندس من النقابة.</p>
                    <p>- صورة عن دفتر خدمة العلم المطلوبة.</p>
                  </div>
                )}
              </Card>
            </Space>
          </Flex>

          <Space>
            <Button type="primary" onClick={handleAddItem}>
              إضافة
            </Button>
            {items.length > 0 && (
              <Button type="default" htmlType="submit" onClick={handleSubmit}>
                إحتساب
              </Button>
            )}
          </Space>
        </Space>
      </form>

      {updatedTableData.length > 0 && (
        <Table
          dataSource={updatedTableData}
          columns={tableColumns}
          rowKey={(record) => record.year + record.month}
          pagination={false}
          size="small"
          bordered
          title={() => (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                fontWeight: "bold",
              }}
            >
              {/* Title on the right */}
              <span style={{ color: "black", fontSize: "16px" }}>
                العائدات التقاعدية
              </span>
              {/* Summary on the left */}
              <span style={{ color: "red", fontSize: "16px" }}>
                مجموع العائدات: {parseInt(totalYields).toLocaleString()}
              </span>
            </div>
          )}
          // Apply different background color per year
          rowClassName={(record) => {
            const color = yearColorMap[record.year];
            return `table-row-${record.year}`; // Add class dynamically
          }}
          // Style dynamically based on the color mapping
          style={{
            "--table-row-background": (record) => yearColorMap[record.year],
          }}
          // Add summary row to show total minimum
          summary={() => (
            <Table.Summary.Row>
              <Table.Summary.Cell
                index={0}
                colSpan={tableColumns.length - 6}
                style={{ textAlign: "right", fontWeight: "bold" }}
              >
                مجموع العائدات
              </Table.Summary.Cell>
              <Table.Summary.Cell index={6} style={{ fontWeight: "bold" }}>
                {parseInt(totalYields).toLocaleString()}{" "}
                {/* Formats number with commas */}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          )}
        />
      )}

      {/* Inline styling for dynamic row classes */}
      <style jsx>
        {Object.entries(yearColorMap)
          .map(
            ([year, color]) =>
              `
              .table-row-${year} {
                background-color: ${color} !important;
              }
            `
          )
          .join("")}
      </style>

      {/* {updatedTableData.length > 0 && (
        <Table
          dataSource={updatedTableData}
          columns={tableColumns}
          rowKey={(record) => record.year + record.month}
          pagination={false}
          size="small"
        />
      )} */}
    </Fragment>
  );
};

export default CalculationForm;

////////////////////////////////////////
////////////////////////////////////////
////////////////////////////////////////
//////////////////////////////////////// Before friday
////////////////////////////////////////
////////////////////////////////////////

// import React, { Fragment, useState, useEffect } from "react";
// import useAuth from "../hooks/useAuth";
// import axios from "../services/api";
// import Flatpickr from "react-flatpickr";
// import "./CalculationForm.css";
// import "flatpickr/dist/flatpickr.min.css";

// const CalculationForm = () => {
//   const { user } = useAuth();

//   const engineerId = user.engineer ? user.engineer_details.id : null;
//   const graduation_date = user.engineer
//     ? user.engineer_details.graduation_date
//     : null;
//   const graduation_date_obj = new Date(graduation_date);
//   const graduate_month = graduation_date_obj.getMonth() + 1;
//   const graduate_year = graduation_date_obj.getFullYear();
//   const graduation_date_str = `${
//     graduate_month < 10 ? `0${graduate_month}` : graduate_month
//   }-${graduate_year}`;

//   const [workTypes, setWorkTypes] = useState([]);
//   const [items, setItems] = useState([]);
//   const [startDate, setStartDate] = useState("");
//   const [endDate, setEndDate] = useState("");
//   const [selectedWorkType, setSelectedWorkType] = useState("");
//   const [showSubmenu, setShowSubmenu] = useState(false);

//   const [employeeType, setEmployeeType] = useState("");
//   const [additionalField, setAdditionalField] = useState(""); // State for the additional field value
//   const [tableData, setTableData] = useState([]);

//   useEffect(() => {
//     const fetchWorkTypes = async () => {
//       try {
//         const response = await axios.get("/api/worktypes/");
//         setWorkTypes(response.data);
//       } catch (error) {
//         console.error("Failed to fetch work types:", error);
//       }
//     };
//     fetchWorkTypes();
//   }, []);

//   const handleAddItem = () => {
//     if (startDate && endDate && selectedWorkType) {
//       setItems([
//         ...items,
//         { startDate, endDate, workType: selectedWorkType, employeeType, additionalField  },
//       ]);
//       setStartDate("");
//       setEndDate("");
//       setSelectedWorkType("");
//       setAdditionalField(""); // Reset additional field after adding
//     }
//   };

//   const handleRemoveItem = (index) => {
//     setItems(items.filter((_, i) => i !== index));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     for (const item of items) {
//       const startDateObj = new Date(item.startDate);
//       const endDateObj = new Date(item.endDate);

//       const startMonth = startDateObj.getMonth() + 1;
//       const startYear = startDateObj.getFullYear();
//       const startMonthStr = `${
//         startMonth < 10 ? `0${startMonth}` : startMonth
//       }-${startYear}`;

//       const endMonth = endDateObj.getMonth() + 1;
//       const endYear = endDateObj.getFullYear();
//       const endMonthStr = `${
//         endMonth < 10 ? `0${endMonth}` : endMonth
//       }-${endYear}`;

//       const requestData = {
//         startMonth: startMonthStr,
//         endMonth: endMonthStr,
//         graduationDate: graduation_date_str,
//         engineer_id: engineerId,
//         additionalField: item.additionalField, // Pass additional field
//         employeeType : item.employeeType,
//       };

//       try {
//         if (item.workType === "offices") {
//           const response = await axios.post("/api/officecalc/", requestData);
//           setTableData((prevData) => [...prevData, ...response.data.data]);
//         } else if (item.workType === "employee") {
//           const response = await axios.post("/api/employeecalc/", requestData);
//           setTableData((prevData) => [...prevData, ...response.data.data]);
//         }
//       } catch (error) {
//         console.error(
//           `Failed to fetch data for work type ${item.workType}:`,
//           error
//         );
//       }
//     }
//   };

//   return (
//     <Fragment>
//       {items.length > 0 && (
//         <div>
//           <hr className="my-5"></hr>
//           <h3>الأنشطة:</h3>
//           <table style={{ width: "50%", borderCollapse: "collapse" }}>
//             <thead>
//               <tr>
//                 <th>إزالة</th>
//                 <th>من تاريخ</th>
//                 <th>إلى تاريخ</th>
//                 <th>نوع العمل</th>
//               </tr>
//             </thead>
//             <tbody>
//               {items.map((item, index) => (
//                 <tr key={index}>
//                   <td
//                     style={{ padding: "8px", borderBottom: "1px solid #ddd" }}
//                   >
//                     <a
//                       onClick={() => handleRemoveItem(index)}
//                       style={{
//                         display: "flex",
//                         alignItems: "center",
//                         marginRight: "8px",
//                         marginLeft: "8px",
//                         cursor: "pointer",
//                       }}
//                     >
//                       <svg
//                         xmlns="http://www.w3.org/2000/svg"
//                         width="24px"
//                         height="24px"
//                         viewBox="0 0 24 24"
//                         style={{ transition: "fill 0.3s" }}
//                       >
//                         <path
//                           fill="red"
//                           d="m8.4L15.6 7L12 10.6L8.4 7L7 8.4l3.6 3.6L7 15.6zm3.6 5q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2t3.9.788t3.175 2.137T21.213 8.1T22 12t-.788 3.9t-2.137 3.175t-3.175 2.138T12 22m0-2q3.35 0 5.675-2.325T20 12t-2.325-5.675T12 4T6.325 6.325T4 12t2.325 5.675T12 20m0-8"
//                         />
//                       </svg>
//                     </a>
//                   </td>
//                   <td
//                     style={{ padding: "8px", borderBottom: "1px solid #ddd" }}
//                   >
//                     {item.startDate}
//                   </td>
//                   <td
//                     style={{ padding: "8px", borderBottom: "1px solid #ddd" }}
//                   >
//                     {item.endDate}
//                   </td>
//                   <td
//                     style={{ padding: "8px", borderBottom: "1px solid #ddd" }}
//                   >
//                     {item.workType}
//                   </td>
//                 </tr>
//               ))}
//               <tr></tr>
//             </tbody>
//           </table>
//           <hr className="my-5"></hr>
//         </div>
//       )}

//       <form onSubmit={handleSubmit}>
//         <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
//           <label>من تاريخ :</label>
//           <input
//             style={{ maxWidth: "150px" }}
//             type="date"
//             value={startDate}
//             onChange={(e) => setStartDate(e.target.value)}
//           />
//           <label>إلى تاريخ :</label>
//           <input
//             style={{ maxWidth: "150px" }}
//             type="date"
//             value={endDate}
//             onChange={(e) => setEndDate(e.target.value)}
//           />

//           <label>نوع العمل :</label>
//           <select
//             style={{ maxWidth: "150px" }}
//             value={selectedWorkType}
//             onChange={(e) => setSelectedWorkType(e.target.value)}
//           >
//             <option value="">اختر نوع العمل</option>
//             {workTypes.map((workType) => (
//               <option key={workType.id} value={workType.id}>
//                 {workType.type}
//               </option>
//             ))}
//           </select>

//           {selectedWorkType === "2" && (
//             <select
//               style={{ maxWidth: "250px" }}
//               value={employeeType}
//               onChange={(e) => setEmployeeType(e.target.value)}
//             >
//               <option value="">اختر نوع الموظف</option>
//               <option value="type_a">موظف لم تقم دائرته بحسم العائدات التقاعدية عنه</option>
//               <option value="type_b">موظف لدى القطاع الخاص مسجل بالتأمينات</option>
//               <option value="type_c">موظف حاصل على اجازة بلا اجر</option>
//             </select>
//           )}

//           {/* Conditionally render the additional field based on selected work type */}
//           {selectedWorkType === "2" && (
//             <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
//               <label>الراتب:</label>
//               <input
//                 style={{ maxWidth: "275px" }}
//                 type="number"
//                 placeholder=" أدخل الراتب الشهري خلال الفترة المحددة"
//                 value={additionalField}
//                 onChange={(e) => setAdditionalField(e.target.value)}
//               />
//             </div>
//           )}
//           {(selectedWorkType === "1" ||
//             selectedWorkType === "3" ||
//             selectedWorkType === "4") && (
//             <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
//               <label>التوريد:</label>
//               <input
//                 style={{ maxWidth: "275px" }}
//                 type="number"
//                 placeholder="ادخل مجموع التوريدات للفترة المحددة"
//                 value={additionalField}
//                 onChange={(e) => setAdditionalField(e.target.value)}
//               />
//             </div>
//           )}

//           <button
//             type="button"
//             className="my-3 btn-green"
//             onClick={handleAddItem}
//           >
//             إضافة
//           </button>
//           {items.length > 0 && <button type="submit">إحتساب</button>}
//         </div>
//       </form>

//       {tableData.length > 0 && (
//         <table>
//           <thead>
//             <tr>
//               <th>السنة</th>
//               <th>الشهر</th>
//               <th>العمر الهندسي</th>
//               <th>الدخل السنوي</th>
//               <th>الحد الأدنى</th>
//             </tr>
//           </thead>
//           <tbody>
//             {tableData.map((row, index) => (
//               <tr key={index}>
//                 <td>{row.year}</td>
//                 <td>{row.month}</td>
//                 <td>{row.eng_age}</td>
//                 <td>{row.year_income}</td>
//                 <td>{row.minimum}</td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       )}
//     </Fragment>
//   );
// };

// export default CalculationForm;

// import React, { Fragment, useState, useEffect } from 'react';
// import useAuth from '../hooks/useAuth';
// import axios from '../services/api';
// import Flatpickr from 'react-flatpickr';
// import 'flatpickr/dist/flatpickr.min.css'; // Import Flatpickr CSS

// const CalculationForm = () => {
//   const { user } = useAuth();

//   // Preparing the request data:
//   const engineerId = user.engineer ? user.engineer_details.id : null;
//   const graduation_date = user.engineer ? user.engineer_details.graduation_date : null;
//   const graduation_date_obj = new Date(graduation_date);
//   const graduate_month = graduation_date_obj.getMonth() + 1;
//   const graduate_year = graduation_date_obj.getFullYear();
//   const graduation_date_str = `${graduate_month < 10 ? `0${graduate_month}` : graduate_month}-${graduate_year}`;

//   const [workTypes, setWorkTypes] = useState([]);
//   const [items, setItems] = useState([]);
//   const [startDate, setStartDate] = useState('');
//   const [endDate, setEndDate] = useState('');
//   const [selectedWorkType, setSelectedWorkType] = useState('');
//   const [tableData, setTableData] = useState([]);

//   useEffect(() => {
//     // Fetch work types
//     const fetchWorkTypes = async () => {
//       try {
//         const response = await axios.get('/api/worktypes/');
//         setWorkTypes(response.data);
//       } catch (error) {
//         console.error('Failed to fetch work types:', error);
//       }
//     };
//     fetchWorkTypes();
//   }, []);

//   const handleAddItem = () => {
//     if (startDate && endDate && selectedWorkType) {
//       setItems([...items, { startDate, endDate, workType: selectedWorkType }]);
//       setStartDate('');
//       setEndDate('');
//       setSelectedWorkType('');
//     }
//   };

//   const handleRemoveItem = (index) => {
//     setItems(items.filter((_, i) => i !== index));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     for (const item of items) {
//       const startDateObj = new Date(item.startDate);
//       const endDateObj = new Date(item.endDate);

//       const startMonth = startDateObj.getMonth() + 1;
//       const startYear = startDateObj.getFullYear();
//       const startMonthStr = `${startMonth < 10 ? `0${startMonth}` : startMonth}-${startYear}`;

//       const endMonth = endDateObj.getMonth() + 1;
//       const endYear = endDateObj.getFullYear();
//       const endMonthStr = `${endMonth < 10 ? `0${endMonth}` : endMonth}-${endYear}`;

//       const requestData = {
//         startMonth: startMonthStr,
//         endMonth: endMonthStr,
//         graduationDate: graduation_date_str,
//         engineer_id: engineerId
//       };

//       try {
//         if (item.workType === 'offices') {
//           const response = await axios.post('/api/officecalc/', requestData);
//           setTableData((prevData) => [...prevData, ...response.data.data]);  // Append new data to the existing state
//         } else if (item.workType === 'employee') {
//           const response = await axios.post('/api/employeecalc/', requestData);
//           setTableData((prevData) => [...prevData, ...response.data.data]);  // Append new data to the existing state
//         }
//         // else if (item.workType === 'employee') {
//         //   const response = await axios.post('/api/employeecalc/', requestData);
//         //   setTableData((prevData) => [...prevData, ...response.data.data]);  // Append new data to the existing state
//         // }
//         // else if (item.workType === 'employee') {
//         //   const response = await axios.post('/api/employeecalc/', requestData);
//         //   setTableData((prevData) => [...prevData, ...response.data.data]);  // Append new data to the existing state
//         // }
//         // else if (item.workType === 'employee') {
//         //   const response = await axios.post('/api/employeecalc/', requestData);
//         //   setTableData((prevData) => [...prevData, ...response.data.data]);  // Append new data to the existing state
//         // }

//       } catch (error) {
//         console.error(`Failed to fetch data for work type ${item.workType}:`, error);
//       }
//     }
//   };

//   return (
//     <Fragment>
//       {items.length > 0 && (
//         <div>
//           <hr className='my-5'></hr>
//           <h3>الأنشطة:</h3>

//           {/* <ul>
//             {items.map((item, index) => (
//               <li key={index} style={{ display: 'flex', alignItems: 'center' }}>
//                 <a
//                   onClick={() => handleRemoveItem(index)}
//                   style={{ display: 'flex', alignItems: 'center', marginRight: '8px', marginLeft: '8px' }}
//                 >
//                   <svg
//                     xmlns="http://www.w3.org/2000/svg"
//                     width="24px"  // Adjust size here
//                     height="24px" // Adjust size here
//                     viewBox="0 0 24 24"
//                     style={{ transition: 'fill 0.3s' }}
//                   >
//                     <path
//                       fill="red"
//                       d="m8.4 17l3.6-3.6l3.6 3.6l1.4-1.4l-3.6-3.6L17 8.4L15.6 7L12 10.6L8.4 7L7 8.4l3.6 3.6L7 15.6zm3.6 5q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2t3.9.788t3.175 2.137T21.213 8.1T22 12t-.788 3.9t-2.137 3.175t-3.175 2.138T12 22m0-2q3.35 0 5.675-2.325T20 12t-2.325-5.675T12 4T6.325 6.325T4 12t2.325 5.675T12 20m0-8"
//                     />
//                   </svg>
//                 </a>
//                 {`من ${item.startDate} إلى ${item.endDate} - ${item.workType}`}
//               </li>
//             ))}
//           </ul> */}

//           <table style={{ width: '50%', borderCollapse: 'collapse' }}>
//             <thead>
//               <tr>
//                 <th>إزالة</th>
//                 <th>من تاريخ</th>
//                 <th>إلى تاريخ</th>
//                 <th>نوع العمل</th>
//               </tr>
//             </thead>
//             <tbody>
//               {items.map((item, index) => (
//                 <tr key={index}>
//                   <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>
//                     <a
//                       onClick={() => handleRemoveItem(index)}
//                       style={{ display: 'flex', alignItems: 'center', marginRight: '8px', marginLeft: '8px', cursor: 'pointer' }}
//                     >
//                       <svg
//                         xmlns="http://www.w3.org/2000/svg"
//                         width="24px"
//                         height="24px"
//                         viewBox="0 0 24 24"
//                         style={{ transition: 'fill 0.3s' }}
//                       >
//                         <path
//                           fill="red"
//                           d="m8.4 17l3.6-3.6l3.6 3.6l1.4-1.4l-3.6-3.6L17 8.4L15.6 7L12 10.6L8.4 7L7 8.4l3.6 3.6L7 15.6zm3.6 5q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2t3.9.788t3.175 2.137T21.213 8.1T22 12t-.788 3.9t-2.137 3.175t-3.175 2.138T12 22m0-2q3.35 0 5.675-2.325T20 12t-2.325-5.675T12 4T6.325 6.325T4 12t2.325 5.675T12 20m0-8"
//                         />
//                       </svg>
//                     </a>
//                   </td>
//                   <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>{item.startDate}</td>
//                   <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>{item.endDate}</td>
//                   <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>{item.workType}</td>
//                 </tr>
//               ))}
//               <tr>

//               </tr>
//             </tbody>
//           </table>

//           <hr className='my-5'></hr>
//         </div>
//       )}
//       <form onSubmit={handleSubmit}>
//         <div class="relative max-w-sm">
//           <label>من تاريخ :</label>
//           <Flatpickr
//             value={startDate}
//             // onChange={(date) => setStartDate(date)}
//             onChange={([date]) => setStartDate(date.toISOString().split('T')[0])}  // Convert to YYYY-MM-DD format
//             options={{ dateFormat: "Y-m-d" }}
//             className='flat-date'
//           />
//           {/* <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} /> */}
//           <label>إلى تاريخ :</label>
//           <Flatpickr
//             value={endDate}
//             // onChange={(date) => setEndDate(date)}
//             onChange={([date]) => setEndDate(date.toISOString().split('T')[0])}  // Convert to YYYY-MM-DD format
//             options={{ dateFormat: "Y-m-d" }}
//             className='flat-date'
//           />
//           {/* <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} /> */}
//           <label>نوع العمل :</label>
//           <select value={selectedWorkType} onChange={(e) => setSelectedWorkType(e.target.value)}>
//             <option value="">اختر نوع العمل</option>
//             {workTypes.map((workType) => (
//               <option key={workType.id} value={workType.type}>{workType.type}</option>
//             ))}
//           </select><br />
//           <button type="button" className="my-3 btn-green" onClick={handleAddItem}>إضافة</button>
//           {items.length > 0 &&
//             <button type="submit" className="mx-3 my-3 btn-green">إحتساب</button>
//           }
//         </div>
//       </form>
//       {tableData.length > 0 && (
//         <table>
//           <thead>
//             <tr>
//               <th>السنة</th>
//               <th>الشهر</th>
//               <th>العمر الهندسي</th>
//               <th>الدخل السنوي</th>
//               <th>الحد الأدنى</th>
//             </tr>
//           </thead>
//           <tbody>
//             {tableData.map((row, index) => (
//               <tr key={index}>
//                 <td>{row.year}</td>
//                 <td>{row.month}</td>
//                 <td>{row.eng_age}</td>
//                 <td>{row.year_income}</td>
//                 <td>{row.minimum}</td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       )}
//     </Fragment>
//   );
// };

// export default CalculationForm;
