import React, { useState } from 'react';
import EngineerPractices from './EngineerPractices';
import EngineerCard from '../components/EngineerCard';
import Card from '../components/UI/Card';
import useAuth from '../hooks/useAuth';
// import CalculationForm from '../components/CalculationForm';
import CalculationForm from '../components/ClaculationFormStyled';

import AutoCalculation from '../components/AutoCalculation';

const CalculationPage = () => {
  const { user } = useAuth();
  const [showCalc, setShowCalc] = useState(false);

  console.log("Current User:", user);

  return (
    <Card>
        <CalculationForm/>
    </Card>
  );
};

export default CalculationPage;