import React from 'react';
import api from '../services/api';
import { useState, useEffect } from 'react';

const DocumentsPage = () => {
    const [Docs, setDocs] = useState([]);

    useEffect(() => {
        api.get('/api/required-documents/')
            .then(response => {
                setDocs(response.data);
                console.log(response.data);
            })
            .catch(error => {
                console.error("Error fetching Docs", error);
            });
    }, []);

    return (
        <div className="p-6 bg-neutral-50 min-h-screen">
            <h3 className="text-xl font-bold text-primary-700 mb-6">الوثائق المطلوبة للتثبيت</h3>
            {
                Docs.length > 0 ? (
                    Docs.map(doc => (
                        <div key={doc.id} className="mb-6 p-4 bg-white shadow-lg rounded-lg border border-neutral-200">
                            <h2 className="text-xl font-semibold text-secondary-600 mb-2">{doc.work_type.type}</h2>
                            <ul className="list-disc pr-5">
                                {doc.documents.map(document => (
                                    <li key={document.id} className="text-neutral-700">{document.title}</li>
                                ))}
                            </ul>
                        </div>
                    ))
                ) : (
                    <p className="text-neutral-500">لا يوجد وثائق لعرضها</p>
                )
            }
        </div>
    );
};

export default DocumentsPage;

