// import React, { useState } from 'react';
// import axios from '../../services/api';
// // import '../styles/LoginPage.css'; // Importing the same styles as Login page
// import EngineerForm from '../../components/EngineerForm';

// const Signup = () => {
//     const [userData, setUserData] = useState({
//         username: '',
//         email: '',
//         password: '',
//         is_engineer: false,
//         is_staff: false,
//         is_admin: false,
//     });

//     const [showEngineerForm, setShowEngineerForm] = useState(false);
//     const [userId, setUserId] = useState(null); // State to store the user ID

//     const handleUserChange = (e) => {
//         const { name, value } = e.target;
//         setUserData(prevData => ({
//             ...prevData,
//             [name]: value
//         }));
//     };

//     const handleSubmit = (e) => {
//         e.preventDefault();

//         axios.post('/api/signup/', userData)
//             .then(response => {
//                 console.log("Signup successful", response.data);
//                 // Assuming the response contains the user ID
//                 const { id } = response.data;
//                 setUserId(id); // Set the user ID in the state
//                 setShowEngineerForm(true); // Show the EngineerForm after successful signup
//             })
//             .catch(error => {
//                 console.error("There was an error signing up!", error);
//             });
//     };

//     return (
//         <div className="login-container"> {/* Using the same container class */}
//             {!showEngineerForm &&
//                 <form className="login-form" onSubmit={handleSubmit}> {/* Using the same form class */}
//                     <div className="form-group">
//                         <input
//                             type="text"
//                             name="username"
//                             value={userData.username}
//                             onChange={handleUserChange}
//                             placeholder="اسم المستخدم"
//                             required
//                         />
//                     </div>
//                     <div className="form-group">
//                         <input
//                             type="email"
//                             name="email"
//                             value={userData.email}
//                             onChange={handleUserChange}
//                             placeholder="البريد الإلكتروني"
//                             required
//                         />
//                     </div>
//                     <div className="form-group">
//                         <input
//                             type="password"
//                             name="password"
//                             value={userData.password}
//                             onChange={handleUserChange}
//                             placeholder="كلمة المرور"
//                             required
//                         />
//                     </div>
//                     <button type="submit" className="login-button">تسجيل</button>
//                 </form>}
//             {showEngineerForm && <EngineerForm userId={userId} />} {/* Pass the user ID as a prop */}
//         </div>
//     );
// };

// export default Signup;











import React, { useState } from 'react';
import axios from '../../services/api';
import EngineerForm from '../../components/EngineerForm';

const Signup = () => {
    const [userData, setUserData] = useState({
        username: '',
        email: '',
        password: '',
        is_engineer: false,
        is_staff: false,
        is_admin: false,
    });

    const [showEngineerForm, setShowEngineerForm] = useState(false);
    const [userId, setUserId] = useState(null); // State to store the user ID
    const [error, setError] = useState(null);
    const [showPassword, setShowPassword] = useState(false);

    const handleUserChange = (e) => {
        const { name, value } = e.target;
        setUserData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        axios.post('/api/signup/', userData)
            .then(response => {
                console.log("Signup successful", response.data);
                const { id } = response.data;
                setUserId(id); // Set the user ID in the state
                setShowEngineerForm(true); // Show the EngineerForm after successful signup

            })
            .catch(error => {
                setError(error);
                console.error("There was an error signing up!", error);
            });
    };

    return (
        <div className="flex justify-center items-center min-h-screen bg-primary-50"> {/* Same container styles as Login */}
            <div className="w-full max-w-md p-8 space-y-6 my-7 bg-white shadow-md rounded-lg">
                {!showEngineerForm &&
                    <form className="space-y-4" onSubmit={handleSubmit}> {/* Same form styles as Login */}
                        <div>
                            <label className="block text-sm font-medium text-gray-700">اسم المستخدم</label>
                            <input
                                type="text"
                                name="username"
                                value={userData.username}
                                onChange={handleUserChange}
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-green-100 focus:border-blue-100"
                                // placeholder="اسم المستخدم"
                                required
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">البريد الإلكتروني</label>
                            <input
                                type="email"
                                name="email"
                                value={userData.email}
                                onChange={handleUserChange}
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-green-100 focus:border-blue-100"
                                // placeholder="البريد الإلكتروني"
                                required
                            />
                        </div>
                        <div className="relative">
                            <label className="block text-sm font-medium text-gray-700">كلمة المرور</label>
                            <input
                                type={showPassword ? 'text' : 'password'} // Toggle input type
                                value={userData.password}
                                name="password"
                                onChange={handleUserChange}
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-green-100 focus:border-blue-100"
                            />
                            {/* Eye Icon */}
                            <div
                                onClick={() => setShowPassword(!showPassword)} // Toggle password visibility
                                className="absolute left-3 bottom-3 cursor-pointer"
                            >
                                {showPassword ? (
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-5 w-5 text-gray-500"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" />
                                        <circle cx="12" cy="12" r="3" />
                                    </svg>
                                ) : (
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-5 w-5 text-gray-500"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" />
                                        <path d="M4.93 4.93l14.14 14.14" />
                                    </svg>
                                )}
                            </div>
                        </div>
                        {/* <div>
                            <label className="block text-sm font-medium text-gray-700">كلمة المرور</label>
                            <input
                                type="password"
                                name="password"
                                value={userData.password}
                                onChange={handleUserChange}
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-green-100 focus:border-blue-100"
                                // placeholder="كلمة المرور"
                                required
                            />
                        </div> */}
                        <button
                            type="submit"
                            className="w-full py-2 px-4 btn-green"
                        >
                            تسجيل
                        </button>
                        {error && <div className='error-message text-center'>{error.message}</div>}
                    </form>}
                {showEngineerForm && <EngineerForm userId={userId} />} {/* Pass the user ID as a prop */}
            </div>
        </div>
    );
};

export default Signup;
