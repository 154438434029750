//////////////////////////////////                              //////////////////////////////////////
//////////////////////////////////                              //////////////////////////////////////
//////////////////////////////////                              //////////////////////////////////////
//////////////////////////////////     Claude Styled            //////////////////////////////////////
//////////////////////////////////                              //////////////////////////////////////
//////////////////////////////////                              //////////////////////////////////////
//////////////////////////////////                              //////////////////////////////////////
//////////////////////////////////                              //////////////////////////////////////



import React, { useEffect, useState } from 'react';
import { api, setAuthToken } from '../services/api';

const EngineerCard = ({ engineerId }) => {
  const [engineer, setEngineer] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    setAuthToken(token);

    api.get(`/api/engineers/${engineerId}/`)
      .then(response => {
        console.log("engineeeeeeeeeeeeer : "+response.data);
        setEngineer(response.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, [engineerId]);

  if (!engineer) {
    return (
      <div className="flex items-center justify-center h-screen">
        <p className="text-xl font-semibold text-gray-600">جاري تحميل البيانات...</p>
      </div>
    );
  }

  const {
    first_name,
    last_name,
    father_name,
    mother_name,
    engineer_number,
    central_number,
    branch,
    home_branch,
    branch_detail,
    home_branch_detail,
    date_of_birth,
    graduation_date,
    join_date,
    gender
  } = engineer;

  return (
    <div className="bg-white shadow-lg rounded-lg overflow-hidden max-w-4xl mx-auto my-8">
      <div className="bg-blue-600 text-white py-4 px-6">
        <h3 className="text-2xl font-bold text-center">ذاتية المهندس</h3>
      </div>
      <div className="p-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <InfoItem label="الاسم الكامل" value={`${first_name} ${father_name} ${last_name}`} />
          <InfoItem label="رقم المهندس" value={engineer_number} />
          <InfoItem label="الفرع" value={branch ? branch_detail.branch_name : 'غير متوفر'} />
          <InfoItem label="تاريخ الميلاد" value={new Date(date_of_birth).toLocaleDateString('ar-SY')} />
          <InfoItem label="اسم الأم" value={mother_name} />
          <InfoItem label="الرقم المركزي" value={central_number} />
          <InfoItem label="الفرع الأم" value={home_branch ? home_branch_detail.branch_name : 'غير متوفر'} />
          <InfoItem label="تاريخ التخرج" value={new Date(graduation_date).toLocaleDateString('ar-SY')} />
          <InfoItem label="تاريخ الانضمام" value={new Date(join_date).toLocaleDateString('ar-SY')} />
          <InfoItem label="الجنس" value={gender ? 'ذكر' : 'أنثى'} />
          <InfoItem label="العمر" value={`${new Date().getFullYear() - new Date(date_of_birth).getFullYear()} سنة`} />
          <InfoItem label="العمر الهندسي" value={`${new Date().getFullYear() - new Date(join_date).getFullYear()} سنة`} />
        </div>
      </div>
    </div>
  );
};

const InfoItem = ({ label, value }) => (
  <div className="bg-gray-100 rounded-lg p-4">
    <p className="text-sm font-semibold text-gray-600 mb-1">{label}</p>
    <p className="text-lg text-gray-800">{value}</p>
  </div>
);

export default EngineerCard;








//////////////////////////////                           /////////////////////////////////////
//////////////////////////////                           /////////////////////////////////////
//////////////////////////////                           /////////////////////////////////////
//////////////////////////////     before claude styled    /////////////////////////////////////
//////////////////////////////                           /////////////////////////////////////
//////////////////////////////                           /////////////////////////////////////
//////////////////////////////                           /////////////////////////////////////


// import React, { useEffect, useState } from 'react';
// import { api, setAuthToken } from '../services/api';
// // import axios from '../services/api';
// const EngineerCard = ({ engineerId }) => {
//   const [engineer, setEngineer] = useState(null);
//   // const [loading, setLoading] = useState(true);
//   // const [error, setError] = useState(null);

//   // useEffect(() => {
//   //   const fetchEngineer = async () => {
//   //     try {
//   //       const response = await api.get(`/api/engineers/${engineerId}/`);
//   //       setEngineer(response.data);
//   //     } catch (error) {
//   //       setError('Failed to fetch engineer data');
//   //     } finally {
//   //       setLoading(false);
//   //     }
//   //   };

//   //   fetchEngineer();
//   // }, [engineerId]);


//   useEffect(() => {
//     // Fetch the token from local storage
//     const token = localStorage.getItem('token');
    
//     // Set the token in the Axios instance
//     setAuthToken(token);

//     // Fetch data from an API endpoint
//     api.get(`/api/engineers/${engineerId}/`)
//       .then(response => {
//         console.log("engineeeeeeeeeeeeer : "+response.data)
//         setEngineer(response.data);
//       })
//       .catch(error => {
//         console.error('Error fetching data:', error);
//       });
//   }, []);

//   // if (loading) {
//   //   return <p>Loading...</p>;
//   // }

//   // if (error) {
//   //   return <p>{error}</p>;
//   // }

//   if (!engineer) {
//     return <p>No engineer data available</p>;
//   }

//   const {
//     first_name,
//     last_name,
//     father_name,
//     mother_name,
//     engineer_number,
//     central_number,
//     branch,
//     home_branch,
//     branch_detail,
//     home_branch_detail,
//     date_of_birth,
//     graduation_date,
//     join_date,
//     gender
//   } = engineer;

//   return (
//     <div>
//       <h3>ذاتية المهندس</h3>
//       <table>
//         <tbody>
//           <tr>
//             <td><strong>الاسم الكامل</strong></td>
//             <td>{first_name + " " + father_name + " " + last_name}</td>
//             <td><strong>رقم المهندس</strong></td>
//             <td>{engineer_number}</td>
//             <td><strong>الفرع</strong></td>
//             <td>{branch?branch_detail.branch_name : 'غير متوفر'}</td>
//             <td><strong>تاريخ الميلاد</strong></td>
//             <td>{new Date(date_of_birth).toLocaleDateString()}</td>
//           </tr>
//           <tr>
//             <td><strong>اسم الأم</strong></td>
//             <td>{mother_name}</td>
//             <td><strong>الرقم المركزي</strong></td>
//             <td>{central_number}</td>
//             <td><strong>الفرع الأم</strong></td>
//             <td>{home_branch?home_branch_detail.branch_name : 'غير متوفر'}</td>
//             <td><strong>تاريخ التخرج</strong></td>
//             <td>{new Date(graduation_date).toLocaleDateString()}</td>
//           </tr>
//           <tr>
//             <td><strong>تاريخ الانضمام</strong></td>
//             <td>{new Date(join_date).toLocaleDateString()}</td>
//             <td><strong>الجنس</strong></td>
//             <td>{gender ? 'ذكر' : 'أنثى'}</td>
//             <td><strong>العمر</strong></td>
//             <td>{new Date().getFullYear() - new Date(date_of_birth).getFullYear()}</td>
//             <td><strong>العمر الهندسي</strong></td>
//             <td>{new Date().getFullYear() - new Date(join_date).getFullYear()}</td>
//           </tr>
//         </tbody>
//       </table>
//     </div>
//   );
// };

// export default EngineerCard;
