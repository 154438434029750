import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import { setAuthToken } from './services/api';
// import Navbar from './components/UI/NavBar';
import Navbar from './components/UI/NavBarStyled';
import Routes from './Routes';



function App() {
  const token = localStorage.getItem('token');
  if (token) {
    setAuthToken(token);
  }

  return (
    <>
      <AuthProvider>
        <Router>
          <Navbar/>
            <Routes />
         
          
        </Router>
      </AuthProvider>
    </>
  );
}

export default App;

