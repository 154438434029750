import React from "react";
import { Routes, Route } from "react-router-dom";
import PrivateRoute from './utils/PrivteRoute';
import LoginPage from './pages/auth/login';
import SignupPage from './pages/auth/Signup';
import DashboardPage from './pages/Dashboard';
import DocumentsPage from './pages/Documents';
import CalculationPage from "./pages/CalculationPage";


const AppRoutes = () => {
    return (
        <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/" element={<LoginPage />} />
            <Route path="/signup" element={<SignupPage />} />
            <Route path="/logout" element={<LoginPage />} />
            <Route element={<PrivateRoute />}>
                <Route path="/dashboard" element={<DashboardPage />} />
                <Route path="/calculation" element={<CalculationPage />} />
                <Route path="/documents" element={<DocumentsPage />} />
            </Route>
        </Routes>
    );
};

export default AppRoutes;

// const AppRoutes = () => {
//     return (
//         <Routes>
//             <Route path="/login" element={<LoginPage />} />
//             <Route path="/signup" element={<SignupPage />} />
//             <Route path="/logout" element={<LoginPage />} />
//             <Route path="/dashboard" element={<PrivateRoute />}>
//                 <Route path="/dashboard" element={<DashboardPage />} />
//                 <Route path="/documents" element={<DocumentsPage />} />
//             </Route>
//         </Routes>
//     );
// };

// export default AppRoutes;




